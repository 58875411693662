export default {
    automationTooltip: 'Consulter l\'automatisation',
    alerts: {
        index: {
            error: 'Échec de la récupération des communications',
        },
        store: {
            success: 'Communication créé avec succès',
            error: 'Échec de la création de la communication',
        },
        show: {
            error: 'Échec de la récupération de la communication',
        },
        update: {
            success: 'Communication mis à jour avec succès',
            error: 'Échec de la mise à jour de la communication',
        },
        destroy: {
            success: 'Communication en cours de suppression...',
            error: 'Échec de la suppression de la communication',
        },
    },
    video: {
        deleteVideo: 'Supprimer',
        description: "La miniature de votre vidéo sera ajoutée à la fin de votre courriel si aucun attribut de modèle n'est choisi.",
        isDeleted: 'Votre vidéo a été supprimée',
        resetVideo: 'Annuler',
        statusMessage: {
            videoCommunicationFail: 'Vous ne pouvez pas envoyer de présentation vidéo pour le moment. Veuillez réessayer plus tard.',
            chooseThumbnail: 'Veuillez choisir une miniature pour votre vidéo.',
            uploading: 'Téléversement...',
            uploaded: 'Traitement...',
            successTitle: 'Téléversement terminé',
            successText: 'Vous pouvez maintenant envoyer votre courriel.',
            wrongFileType: 'Le téléchargement du fichier a échoué. Veuillez choisir un fichier valide et réessayer.',
        },
        ziggeoTranslations: {
            record: 'Enregistrer',
            upload: 'Téléverser',
        },
        thumbnailPlaceholder: 'Miniature vidéo',
        title: 'Présentation vidéo',
        warning: 'Vous serez notifé quand votre vidéo sera téléversée.',
    },
    videoconference: {
        adminUnauthorized: "En tant qu'admin, vous ne pouvez utiliser le module Vidéoconférence.",
        impersonateUnauthorized: 'Vous ne pouvez utiliser le module Vidéoconférence en mode personnification. Vous devez vous connecter directement dans le compte utilisateur.',
        join: 'Joindre la vidéoconférence',
        needClientName: "Vous devez inscrire le nom du client dans la fiche avant d'utiliser la Vidéoconférence.",
        needToReconnect: "Vous devez vous reconnecter au CRM afin d'utiliser le module Vidéoconférence.",
        needVideoconference: 'Contactez-nous pour activer la vidéoconférence.',
        noVideo: 'Aucun vidéo',
        openVideo: "Écouter l'enregistrement",
        processing: 'Enregistrement en traitement...',
        hyundaiDisabled: 'Aucun événement virtuel de planifié pour ce lead',

        invitation: {
            failed: "Une erreur s'est produite, aucune invitation n'a pu être envoyée. Veuillez réessayer.",
            join: 'Joindre',
            modalTitle: 'Appel vidéoconférence',
            newInvite: 'Nouvelle invitation',
            noContactInfo: '@:client.noEmail',
            sentMessage: 'Une invitation pour un appel vidéoconférence a déjà été envoyée le {0}.',
            success: "Invitation envoyée avec succès. Vous recevrez une alerte lorsque le client joindra l'appel.",
            unsubscribedFromEmail: "Veuillez noter que le client s'est désabonné des envois courriel",

            method: {
                email: 'Invitation par Courriel',
            },
        },
    },
    email: {
        bouncedReasons: {
            blockedAddress: 'Le destinataire que vous tentez de joindre a été bloqué.',
            emailAccountDoesntExist: "Le compte de messagerie n'existe pas ou a été désactivé.",
            emailNotDelivered: "Votre message n'a pas pu être livré à un ou plusieurs destinataires.",
            userMarkedYourMessagesAsSpam: 'Le destinataire a signalé vos messages comme pourriel.',
            mailboxDisabledOrInvalid: "La boîte courriel du destinataire n'est pas valide ou a été désactivée.",
            previouslyBouncedEmail: 'Votre message a été précédemment rejeté.',
            invalidCharacter: 'Votre message contient des caractères invalides.',
            tooBig: 'Votre message contient un fichier trop volumineux.',
            overQuota: "Le destinataire que vous tentez de joindre a dépassé sa limitation d'espace.",
            policyReason: 'Votre message enfreint les règles de la politique.',
            notAuthorized: "Vous n'êtes pas autorisé à envoyer un courriel à cette adresse.",
            requireEmailCheck: "Votre client de messagerie n'est pas configuré pour l'authentification SMTP.",
        },
    },
    phone: {
        recordingLoading: 'Enregistrement en traitement..',
        status: 'Statut',
        answered: 'Répondu',
        attempted: 'Tentative',
        calling: 'En tentative',
        delivery_failed: 'Échoué',
        error: 'Erreur',
        interrupted: 'Interrompu',
        invalid: 'Invalide',
        pending: 'En attente',
        unanswered: 'Sans réponse',
    },
};
