export default {
    automationTooltip: 'Go to automation',
    alerts: {
        index: {
            error: 'Failed fetching the communications',
        },
        store: {
            success: 'Communication created successfully',
            error: 'Failed creating the communication',
        },
        show: {
            error: 'Failed fetching the communication',
        },
        update: {
            success: 'Communication updated successfully',
            error: 'Failed updating the communication',
        },
        destroy: {
            success: 'Communication is being deleted...',
            error: 'Failed deleting the communication',
        },
    },
    video: {
        deleteVideo: 'Delete',
        description: 'Your video thumbnail will be added at the end of your message if no template attribute is set.',
        isDeleted: 'You video has been deleted',
        resetVideo: 'Cancel',
        statusMessage: {
            videoCommunicationFail: "You can't send a video presentation right now. Please try later.",
            chooseThumbnail: 'Please choose a thumbnail for your video.',
            uploading: 'Uploading...',
            uploaded: 'Processing...',
            successTitle: 'Upload Completed',
            successText: 'You can now send your email.',
            wrongFileType: 'The file upload failed. Please choose a valid file and try again.',
        },
        ziggeoTranslations: {
            record: 'Record',
            upload: 'Upload',
        },
        thumbnailPlaceholder: 'Video thumbnail',
        title: 'Video presentation',
        warning: 'You will be notified when your video is uploaded.',
    },
    videoconference: {
        adminUnauthorized: 'As an admin, you cannot use the Videoconference module.',
        impersonateUnauthorized: 'You cannot use the Videoconference module in impersonate mode. You must log in directly in the user account.',
        join: 'Join the videoconference',
        needClientName: 'You must enter the client name in the client card before using Videoconference.',
        needToReconnect: 'You must reconnect to the CRM to use the Videoconference module.',
        needVideoconference: 'Contact us to activate videoconference.',
        noVideo: 'No video',
        openVideo: 'Listen to the recording',
        processing: 'Recording in processing...',
        hyundaiDisabled: 'There are no virtual event planned for this lead',

        invitation: {
            failed: 'An error occured, no invitation was sent. Please try again.',
            join: 'Join',
            modalTitle: 'Videoconference call',
            newInvite: 'New invite',
            noContactInfo: '@:client.noEmail',
            sentMessage: 'An invitation for a videoconference has already been sent on {0}.',
            success: 'Invitation sent successfully. You will receive an alert when the customer joins the call.',
            unsubscribedFromEmail: 'Please note that the customer has unsubscribed from email communications',

            method: {
                email: 'Invite by Email',
            },
        },
    },
    email: {
        bouncedReasons: {
            blockedAddress: 'The recipient you are trying to reach has been blocked',
            emailAccountDoesntExist: 'The email account does not exist or has been disabled.',
            emailNotDelivered: 'Your message could not be delivered to one or more recipients.',
            userMarkedYourMessagesAsSpam: 'The recipient has marked your messages as spam.',
            mailboxDisabledOrInvalid: "The recipient's mailbox is invalid or has been disabled.",
            previouslyBouncedEmail: 'Your message was previously rejected.',
            invalidCharacter: 'Your message contains invalid characters.',
            tooBig: 'The recipient you are trying to reach has exceeded its space limit.',
            overQuota: "Your email hasn't been sent, because the account you tried to reach exceded it's space limitation",
            policyReason: 'Your message violates policy rules.',
            notAuthorized: 'You are not authorized to send an email to this address.',
            requireEmailCheck: 'Your email client is not configured for SMTP authentication.',
        },
    },
    phone: {
        recordingLoading: 'Recording is being processed...',
        status: 'Status',
        answered: 'Answered',
        attempted: 'Attempted',
        calling: 'Calling',
        delivery_failed: 'Delivery failed',
        error: 'Error',
        interrupted: 'Interrupted',
        invalid: 'Invalid',
        pending: 'Pending',
        unanswered: 'Unanswere',
    },
};
