import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';

export default class ConditionQuestionAdjustment {
    private question_id: string = '';

    private adjustment: int = 0;

    static get defaults() {
        return {
            question_id: '',
            adjustment: 0,
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, ConditionQuestionAdjustment.defaults));
    }
}
