import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';
import ActivixDate from '@/value-objects/ActivixDate.js';

export default class UserIdvDetail {
    first_name: string = '';

    middle_name: string = '';

    last_name: string = '';

    email: string = '';

    phone: string = '';

    address_line1: string = '';

    city: string = '';

    province: string = '';

    postal_code: string = '';

    is_max_limit_reached: boolean = false;

    expiry_date: string = '';

    static get defaults() {
        return {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone: '',
            address_line1: '',
            city: '',
            province: '',
            postal_code: '',
            is_max_limit_reached: false,
            expiry_date: '',
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, UserIdvDetail.defaults));
    }

    get isExpired(): boolean {
        return this.expiryDate.isPast();
    }

    get isUnavailable(): boolean {
        return this.is_max_limit_reached;
    }

    get expiryDate(): ActivixDate {
        return new ActivixDate(this.expiry_date);
    }
}
