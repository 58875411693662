import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';
import ValueAdjustment from '@/entities/ValueAdjustment.ts';

export default class AdditionalDetail {
    private eti_value: number = 0;

    private eti_range_low: number = 0;

    private eti_range_high: number = 0;

    private creation_date: string = '';

    private eti_id: string = '';

    private language: string = '';

    private _value_adjustment: ValueAdjustment = new ValueAdjustment();

    static get defaults() {
        return {
            eti_value: 0,
            eti_range_low: 0,
            eti_range_high: 0,
            creation_date: '',
            eti_id: '',
            language: '',
            _value_adjustment: new ValueAdjustment(),
        };
    }

    set value_adjustment(valueAdjustment) {
        this._value_adjustment = new ValueAdjustment(valueAdjustment);
    }

    get value_adjustment(): ValueAdjustment {
        return this._value_adjustment;
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, AdditionalDetail.defaults));
    }
}
