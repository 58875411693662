import { getOnce } from '@/plugins/axios.js';
import PreQualification from '@/entities/PreQualification.ts';
import InvalidPayload from '../exceptions/InvalidPayload.js';

interface ShowResponse {
    data: PreQualification
}

export default {
    async show(id: string) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await getOnce(`v1/leads/${id}/get-prequalification`) as ShowResponse;
        if (response === null) {
            return null;
        }

        return response.data;
    },
};
