import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';

export default class VehicleFinancial {
    private balance_amount: number = 0;

    private offer_amount: number = 0;

    static get defaults() {
        return {
            balance_amount: 0,
            offer_amount: 0,
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, VehicleFinancial.defaults));
    }
}
