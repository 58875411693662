<template>
    <div class="flex items-center border-t border-gray-200">
        <div
            class="flex-1 cursor-pointer overflow-hidden"
            :class="selectedForViewing ? 'bg-gray-200' : 'hover:bg-gray-100'"
            @click="onClick"
        >
            <div class="flex items-center">
                <div class="pl-4 py-4 | md:py-6">
                    <add-lead-result-item-icon :lead="lead" />
                </div>
                <div class="flex-1 px-4 py-4 overflow-hidden whitespace-nowrap | md:py-6">
                    <div>
                        <div class="flex justify-between">
                            <div class="font-semibold truncate text-gray-650 | md:text-lg">
                                {{ lead.fullName || $t('clientCard.unknown') }} {{ associatedLeadsCount }}
                            </div>
                            <div class="flex items-center justify-end">
                                <add-lead-result-item-search-icons class="px-2 | lg:hidden" :lead="lead" :searched-parameters="searchedParameters" />
                                <activix-tooltip :content="dateTime">
                                    <div class="text-gray-600" v-text="date" />
                                </activix-tooltip>
                            </div>
                        </div>
                        <div class="flex justify-between mt-1">
                            <activix-tooltip :content="vehicleTooltip" :only-on-truncate="true">
                                <div class="truncate" v-text="vehicle" />
                            </activix-tooltip>

                            <div class="flex items-center ml-4 max-w-1/2">
                                <div class="mr-1">
                                    <icon class="text-gray-600" :name="$icons.advisor" />
                                </div>
                                <div class="truncate" v-text="advisorName" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 hidden | lg:flex lg:justify-between">
                        <add-lead-result-item-search-icons :lead="lead" :searched-parameters="searchedParameters" />
                        <add-lead-result-item-buttons
                            :selected-for-viewing="selectedForViewing"
                            :has-completable-values="hasCompletableValues"
                            @complete="onComplete"
                            @associate="onAssociate"
                        />
                    </div>
                </div>
            </div>
            <add-lead-result-item-buttons
                class="px-4 pb-4 | lg:hidden"
                :selected-for-viewing="selectedForViewing"
                :has-completable-values="hasCompletableValues"
                @complete="onComplete"
                @associate="onAssociate"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import { get } from 'lodash-es';

    import AddLead from '@/mixins/AddLead.js';
    import AddLeadResultItemButtons from '@/components/add_lead/AddLeadResultItemButtons.vue';
    import AddLeadResultItemIcon from '@/components/add_lead/AddLeadResultItemIcon.vue';
    import AddLeadResultItemSearchIcons from '@/components/add_lead/AddLeadResultItemSearchIcons.vue';

    import Account from '@/entities/Account.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Division from '@/entities/Division.js';
    import Lead from '@/entities/Lead.js';
    import LeadType from '@/entities/LeadType.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        inject: ['$association'],

        components: {
            AddLeadResultItemButtons,
            AddLeadResultItemIcon,
            AddLeadResultItemSearchIcons,
        },

        mixins: [AddLead],

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            newLead: {
                type: Object,
                default: () => {},
            },
            searchedParameters: {
                type: Object,
                default: () => {},
            },
            state: {
                type: Object,
                default: () => {},
            },
            itemSelectedForViewing: {
                type: Boolean,
                default: false,
            },
            account: {
                type: Account,
                required: true,
            },
            inventoryUnit: {
                type: Object,
                required: false,
            },
        },

        data: () => ({
            advisorName: null,
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            association() {
                return this.$association();
            },

            associatedLeadsCount() {
                if (!this.lead.associated_leads) {
                    return '';
                }

                if (!this.lead.associated_leads.length) {
                    return '';
                }

                return `(${this.lead.associated_leads.length})`;
            },

            completableValues() {
                const values = {};

                values.civility = this.isCompletable('civility');
                values.last_name = this.isCompletable('name');
                values.business_name = this.isCompletable('businessName');

                values.phone = this.phoneCompletableValue;
                values.email = this.emailCompletableValue;

                values.division = this.isCompletable('division_id');
                values.source = this.isCompletable('source');

                values.advisor = this.advisorCompletableValue;
                values.bdc = this.bdcCompletableValue;
                values.serviceAgent = this.isCompletable('serviceAgent');
                values.serviceAdvisor = this.isCompletable('serviceAdvisor');

                values.endContractDate = this.isCompletable('endContractDate');
                values.inventory_unit_id = this.isCompletable('inventory_unit_id');

                const returnValues = {};

                Object.keys(values)
                    .filter(key => !!values[key])
                    .forEach(key => {
                        returnValues[key] = values[key];
                    });

                return returnValues;
            },

            advisorCompletableValue() {
                let advisorValue = this.isCompletable('advisor');

                if (!advisorValue && !this.lead.userId && this.authUser.isAdvisor()) {
                    advisorValue = this.authUser.id;
                }

                return advisorValue;
            },

            bdcCompletableValue() {
                let bdcValue = this.isCompletable('bdc');

                if (!bdcValue && !this.lead.bdc && this.authUser.isBdc()) {
                    bdcValue = this.authUser.id;
                }

                return bdcValue;
            },

            phoneCompletableValue() {
                if (
                    this.isValidPhone &&
                    (!this.lead.phones.length ||
                        (this.lead.phones.length < 3 &&
                            this.lead.searchScores.phone.matched < this.searchScoreValues.phone))
                ) {
                    return {
                        number: this.newLead.phone,
                        type: this.phoneTypeToUpdate,
                    };
                }

                return '';
            },

            phoneTypeToUpdate() {
                const phoneToUpdate = ['home', 'work', 'cell'].filter(
                    item => !this.lead.phones
                        .map(phone => {
                            return phone.type;
                        })
                        .includes(item),
                );

                return phoneToUpdate[0];
            },

            emailCompletableValue() {
                if (
                    this.isValidEmail &&
                    (!this.lead.emails.length ||
                        (this.lead.emails.length < 2 &&
                            this.lead.searchScores.email.matched < this.searchScoreValues.email))
                ) {
                    return {
                        email: this.newLead.email,
                        type: this.emailTypeToUpdate,
                    };
                }

                return '';
            },

            emailTypeToUpdate() {
                const emailToUpdate = ['home', 'work'].filter(
                    item => !this.lead.emails
                        .map(email => {
                            return email.type;
                        })
                        .includes(item),
                );

                return emailToUpdate[0];
            },

            hasCompletableValues() {
                const values = this.completableValues;
                return Object.keys(values).some(key => !!values[key]);
            },

            selectedForViewing() {
                return (
                    (!this.xsLayout && this.$route.name == 'leads.update' && this.lead.id == this.$route.params.id) ||
                    (this.xsLayout && this.itemSelectedForViewing)
                );
            },

            date() {
                return new ActivixDate(this.lead.created_at).toHumanShort(false);
            },

            dateTime() {
                return `${this.$t('addLead.createdAt')} ${new ActivixDate(this.lead.created_at).toHumanShort(true)}`;
            },

            vehicleString() {
                const vehicle = new LeadVehicle(this.lead.getSoldOrFirstWantedVehicle() || {});
                let value = vehicle.toString();

                if (vehicle.stock) {
                    value += ` (#${vehicle.stock})`;
                }

                return value.trim();
            },

            leadHasVehicle() {
                return !!this.vehicleString;
            },

            vehicle() {
                return this.vehicleString || this.$t('addLead.noVehicle');
            },

            vehicleTooltip() {
                if (!this.leadHasVehicle) {
                    return '';
                }

                return this.vehicle;
            },
        },

        watch: {
            'lead.id': {
                immediate: true,
                async handler() {
                    const advisorUser = await this.lead.getUser(['first_name', 'last_name']);

                    this.advisorName = advisorUser.shortName || this.$t('addLead.noAdvisor');
                },
            },
        },

        methods: {
            onClick() {
                if (!this.selectedForViewing) {
                    this.$emit('navigate-to-lead');
                }
            },

            associate() {
                if (this.association.disabled) {
                    return;
                }

                this.$emit('associate');
            },

            onComplete() {
                if (this.selectedForViewing) {
                    this.$emit('select-lead-for-completion', this.completableValues);
                }
            },

            onAssociate() {
                this.$emit('select-lead-for-association');
            },

            isCompletable(field) {
                if (['civility', 'name', 'businessName', 'inventory_unit_id'].includes(field)) {
                    let isCompletable = false;
                    let wantedVehicles = {};

                    switch (field) {
                        case 'civility':
                            isCompletable = !!(!get(this.lead, field, null) && !!get(this.newLead, field, null));
                            break;
                        case 'name':
                            isCompletable = !!(!get(this.lead, 'fullName', null) && !!get(this.newLead, field, null));
                            break;
                        case 'businessName':
                            isCompletable = !this.lead?.business_name && !!this.newLead?.businessName;
                            break;
                        case 'inventory_unit_id':
                            if (!this.inventoryUnit?.id) {
                                break;
                            }

                            wantedVehicles = this.lead.vehicles.filter(vehicle => vehicle.type === 'wanted');

                            isCompletable = !wantedVehicles.some(vehicle => vehicle.inventory_unit_id === this.inventoryUnit.id);
                            break;
                    }

                    if (!isCompletable) {
                        return '';
                    }

                    return this.computeCompletableValue(field);
                }

                const mayAssign =
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isSuperBdc();

                let rulePassed = false;
                let exchangeVehicles = {};

                switch (field) {
                    case 'advisor':
                        rulePassed =
                            !this.lead.userId && (mayAssign || this.account.assigned_lead || this.authUser.isBdc());
                        break;

                    case 'bdc':
                        if (mayAssign) {
                            rulePassed = true;
                            break;
                        }

                        if (!this.lead.bdcUserId) {
                            if (this.authUser.isAdvisor() && this.account.unrestricted_assignment) {
                                rulePassed = true;
                                break;
                            }

                            if (this.authUser.hasAdvisorSkills() && !this.account.assigned_lead) {
                                rulePassed = false;
                                break;
                            }

                            rulePassed = true;
                            break;
                        }

                        break;

                    case 'serviceAdvisor':
                        rulePassed = this.lead.division_id == Division.SERVICE && !this.lead.serviceAdvisor;
                        break;

                    case 'serviceAgent':
                        rulePassed = this.lead.division_id == Division.SERVICE && !this.lead.serviceAgent;
                        break;

                    case 'division_id':
                        rulePassed = !this.lead.division_id;
                        break;

                    case 'source':
                        rulePassed = this.lead.lead_type_id != LeadType.RENEWAL && !this.lead.source;
                        break;

                    case 'endContractDate':
                        exchangeVehicles = this.lead.vehicles.filter(vehicle => vehicle.type == 'exchange');

                        rulePassed =
                            this.lead.lead_type_id == LeadType.RENEWAL &&
                            this.isRenewal &&
                            exchangeVehicles.length &&
                            !exchangeVehicles.some(vehicle => !!vehicle.end_contract_date);
                        break;
                }

                if (this.state.showAdditionalFields && rulePassed) {
                    return this.computeCompletableValue(field) || '';
                }

                return '';
            },

            computeCompletableValue(field) {
                let returnValue = {};

                switch (field) {
                    case 'name':
                        returnValue = this.newLead.name;
                        break;

                    case 'civility':
                        returnValue = this.newLead.civility;
                        break;

                    case 'division_id':
                        returnValue = this.newLead.division;
                        break;

                    case 'source':
                        returnValue = this.newLead.source;
                        break;

                    case 'inventory_unit_id':
                        returnValue = `#${this.inventoryUnit.stock}`;
                        break;

                    default:
                        returnValue = this.newLead[`${field}`];
                }

                return returnValue;
            },
        },
    };
</script>
