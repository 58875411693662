import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';
import { isPreQualificationCreditTier, PreQualificationCreditTier } from '@/entities/types/PreQualificationCreditTier.ts';
import { isPreQualificationCategory, PreQualificationCategory } from '@/entities/types/PreQualificationCategory.ts';

export default class PreQualOffer {
    lender_name: string = '';

    credit_tier: string = '';

    credit_score_range: string = '';

    interest_rate: string = '';

    payment_term: string = '';

    category: string = '';

    payment_amount: string = '';

    max_amount: string = '';

    static get defaults() {
        return {
            lender_name: '',
            credit_tier: '',
            credit_score_range: '',
            interest_rate: '',
            payment_term: '',
            category: '',
            payment_amount: '',
            max_amount: '',
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, PreQualOffer.defaults));
    }

    get isPrimeOrSuperPrime(): boolean {
        if (isPreQualificationCreditTier(this.credit_tier)) {
            return [PreQualificationCreditTier.Prime, PreQualificationCreditTier.SuperPrime].includes(this.credit_tier);
        }
        return false;
    }

    get creditCategory(): PreQualificationCategory | null {
        if (isPreQualificationCategory(this.category)) {
            return this.category;
        }

        return null;
    }
}
