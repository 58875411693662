import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';

export default class ConditionQuestion {
    private question_id: string = '';

    private question_text: string = '';

    private answer_text: string = '';

    static get defaults() {
        return {
            question_id: '',
            question_text: '',
            answer_text: '',
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, ConditionQuestion.defaults));
    }
}
