import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';
import PreQualOffer from '@/entities/PreQualOffer.ts';
import ActivixDate from '@/value-objects/ActivixDate.js';
import { PreQualificationCategory } from '@/entities/types/PreQualificationCategory.ts';

export default class PreQualDetail {
    private _pre_qual_offers: PreQualOffer[] = [];

    is_expired: boolean = false;

    is_approved: boolean = false;

    expiry_date: string = '';

    static get defaults() {
        return {
            _pre_qual_offers: [],
            is_expired: false,
            is_approved: false,
            expiry_date: '',
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, PreQualDetail.defaults));
    }

    set pre_qual_offers(preQualOffers: any[]) {
        this._pre_qual_offers = preQualOffers.map((offer: any) => new PreQualOffer(offer));
    }

    get pre_qual_offers(): PreQualOffer[] {
        return this._pre_qual_offers;
    }

    get hasOffers(): boolean {
        return this.pre_qual_offers.length > 0;
    }

    get minimumAmount(): string {
        return Math.min(...this.pre_qual_offers.map((offer) => +offer.max_amount)).toString();
    }

    get maximumAmount(): string {
        return Math.max(...this.pre_qual_offers.map((offer) => +offer.max_amount)).toString();
    }

    get isPrimeOrSuperPrime(): boolean {
        return this.pre_qual_offers.some((offer) => {
            return offer.isPrimeOrSuperPrime;
        });
    }

    get isExpired(): boolean {
        return this.is_expired || this.expiryDate.isPast();
    }

    get expiryDate(): ActivixDate {
        return new ActivixDate(this.expiry_date);
    }

    get isPoorCredit(): boolean {
        return this.pre_qual_offers.some((offer) => {
            return offer.creditCategory === PreQualificationCategory.Poor;
        });
    }

    get isFairCredit(): boolean {
        return this.pre_qual_offers.some((offer) => {
            return offer.creditCategory === PreQualificationCategory.Fair;
        });
    }

    get isGoodCredit(): boolean {
        return this.pre_qual_offers.some((offer) => {
            return offer.creditCategory === PreQualificationCategory.Good;
        });
    }

    get isVeryGoodCredit(): boolean {
        return this.pre_qual_offers.some((offer) => {
            return offer.creditCategory === PreQualificationCategory.VeryGood;
        });
    }

    get isExcellentCredit(): boolean {
        return this.pre_qual_offers.some((offer) => {
            return offer.creditCategory === PreQualificationCategory.Excellent;
        });
    }
}
