import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';
import PrequalDetail from '@/entities/PreQualDetail.ts';
import UserIdvDetail from '@/entities/UserIdvDetail.ts';

export default class PreQualification {
    private _pre_qual_detail: PrequalDetail | null = null;

    private _user_idv_detail: UserIdvDetail | null = null;

    static get defaults() {
        return {
            _pre_qual_detail: null,
            _user_idv_detail: null,
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, PreQualification.defaults));
    }

    set pre_qual_detail(preQualDetail) {
        this._pre_qual_detail = preQualDetail ? new PrequalDetail(preQualDetail) : null;
    }

    set user_idv_detail(userIdvDetail) {
        this._user_idv_detail = userIdvDetail ? new UserIdvDetail(userIdvDetail) : null;
    }

    get pre_qual_detail(): PrequalDetail | null {
        return this._pre_qual_detail;
    }

    get user_idv_detail(): UserIdvDetail | null {
        return this._user_idv_detail;
    }
}
