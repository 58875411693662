import Enum from './Enum.js';

export default class CommunicationStatus extends Enum {
    static get entries() {
        return {
            answered: 'answered',
            attempted: 'attempted',
            calling: 'calling',
            delivery_failed: 'delivery_failed',
            error: 'error',
            interrupted: 'interrupted',
            invalid: 'invalid',
            pending: 'pending',
            unanswered: 'unanswered',
        };
    }

    static get ANSWERED() {
        return this.entries.answered;
    }

    static get ATTEMPTED() {
        return this.entries.attempted;
    }

    static get CALLING() {
        return this.entries.calling;
    }

    static get DELIVERY_FAILED() {
        return this.entries.delivery_failed;
    }

    static get ERROR() {
        return this.entries.error;
    }

    static get INTERRUPTED() {
        return this.entries.interrupted;
    }

    static get INVALID() {
        return this.entries.invalid;
    }

    static get PENDING() {
        return this.entries.pending;
    }

    static get UNANSWERED() {
        return this.entries.unanswered;
    }
}
