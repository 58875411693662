import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';

export default class VehicleDetails {
    year: number | null = null;

    make: string = '';

    model: string = '';

    trim: string = '';

    vin: string = '';

    mileage: number = 0;

    color: string = '';

    additional_options: string = '';

    original_owner: boolean = false;

    payment_on_vehicle: string = '';

    static get defaults() {
        return {
            year: 0,
            make: '',
            model: '',
            trim: '',
            vin: '',
            mileage: 0,
            color: '',
            additional_options: '',
            original_owner: false,
            payment_on_vehicle: '',
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, VehicleDetails.defaults));
    }
}
