export default {
    alerts: {
        stats: {
            error: 'Échec de la récupération des statistiques',
        },
        leads: {
            error: 'Échec de la récupération des leads',
        },
        graphs: {
            error: 'Échec de la récupération des graphiques',
        },
    },

    clickHereToGroupByClientSource: 'Cliquez pour regrouper par sources du client',
    clickHereToGroupByApprovedSource: 'Cliquez pour regrouper par source approuvé',
    filterPlaceholder: 'Filtrer...',
    activityReport: "Rapport d'activités",
    allLead: 'Mes clients',
    commercial: 'Directeur commercial',
    event: 'Événement',
    leadXpress: 'Lead Web',
    loyalty: 'Loyauté',
    phoneUp: 'Phone-up',
    renewal: '@.capitalize:lexicon.portfolio.singular',
    saleTable: 'Tableau des @.capitalize:lexicon.sale.plural',
    service: 'Service',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    webBoost: 'WebBoost',
    webOrder: 'Commande Web',

    phoneUpBy: 'Phone-up par',
    phoneUpByAccount: 'Phone-up par',
    clientBy: 'Clients par',
    clientByAccount: 'Clients par',
    leadBy: 'Leads par',
    leadByAccount: 'Leads par',
    walkInBy: '@.capitalize:lexicon.walkIn.singular par',
    walkInByAccount: '@.capitalize:lexicon.walkIn.singular par',
    webOrderBy: 'Commandes par',
    webOrderByAccount: 'Commandes par',

    leadsSelectedOnThisPage: '{count} lead selectionné. | {count} leads sélectionnés.',
    allLeadsSelected: 'Tout les {count} leads sont sélectionnés.',
    selectAllLeads: 'Sélectionner tous les {count} leads.',
    unselectAllLeads: 'Effacer la sélection.',
    disabledForGroups: 'Module non disponible pour les groupes.',
    moduleNotEnabledForAccount: 'Module non disponible pour ce compte.',
    noResults: 'Aucun résultat',
    tooManyResults: 'Plus de 10 000 résultats. Veuillez réduire votre plage de dates pour voir les leads.',
    tooManySalesResults: 'Plus de 1 000 résultats. Veuillez réduire votre plage de dates pour voir les @:lexicon.sale.plural .',
    leadSelected: 'lead sélectionné | leads sélectionnés',
    tooMuchLeadWarning:
        "L'affichage de 100 leads et plus peut ralentir la récupération des données. @:general.wishToProceed",
    enterValidPhone: 'Veuillez entrer un téléphone valide ou laisser le champ vide',
    addContactInfo: 'Ajouter moyen de contact',
    overwrite: 'Mettre à jour',
    dateMultiselectTooltip:
        'Choisissez les champs qui seront filtrés par votre plage de date. Chaque champ est filtré indépendamment des autres',
    infoDashboardView: 'Sauvegardez vos filtres et configurations actuels pour chaque tableau de bord',
    saveDashboardViewTooltip: 'Enregistrer une vue personnalisée',
    addDashboardView: 'Ajouter une vue',
    editDashboardView: 'Modifier cette vue',
    deleteDashboardView: 'Supprimer cette vue',
    shareDashboardView: 'Partagez cette vue',
    viewOwner: 'Propriétaire',
    displayDashboardView: 'Information de cette vue',
    viewSaveDate: 'Enregistrer les dates',
    viewSaveDateTooltip: 'Enregistrer les dates actuellement sélectionnées pour cette vue',
    addEventLeads: 'Ajouter un événement',
    eventBeingCreated: 'Événement en cours de création...',
    eventAddingLeads: 'Ajout des leads à l\'événement {event} ...',
    massAction: 'Autres actions',
    infoAddEventLeads: 'Ajouter à un événement',
    apptByPhone: 'Rendez-vous téléphonique',
    vehicleFieldRequiresModelOrYear: 'Le véhicule doit inclure un modèle ou une année',
    addOrModifyAppointment:
        'Modifier le rendez-vous téléphonique est irréversible et pourrait affecter vos statistiques.',
    infoSubscription: "Permet de s'abonner pour recevoir un rapport de cette vue par courriel",
    save: 'Sauvegarder',
    renewalAbr: '@.capitalize:lexicon.portfolio.abr',
    renewalBdcAggregation: 'Consolidation des statistiques des agents BDC, exclue des totaux',
    prebooking: 'Pré-book.',
    sms: 'SMS',
    newEvent: 'Nouvel événement',
    eventName: 'Nom de l\'événement',
    segment: 'Segment',
    webLead: 'Lead Web',
    dateFilterTooltip: 'Ce filtre se base uniquement sur le jour, le mois et l\'année (ex. "21 oct 2018")',
    dateFilterWithoutYearTooltip: 'Ce filtre se base uniquement sur le jour et le mois (ex. "21 oct")',
    importFileTooltip: 'Nom du fichier à partir duquel le lead a été importé',
    applyFilter: 'Appliquer',
    clearFilter: 'Effacer',
    createdByUser: 'Lead ajouté manuellement par un utilisateur',
    createdBy: 'Créé par',
    updatedBy: 'Modifié par',
    filter: 'Filtrer',
    toggleColumns: 'Affichage colonnes',
    campaigns: 'Campagnes',
    importedCampaign: 'Campagne importée',
    inDate: "Résultat à l'intérieur des dates",
    notInDate: "Résultat à l'extérieur des dates",
    dateNotInRange: "Cette date est à l'extérieur de votre plage de dates",
    downloadScanXpress: 'Téléchargez Scan Xpress',
    profitAndAverage: 'Profits et moyennes',
    noData: 'Aucune donnée disponible dans le tableau',
    refresh: 'Rafraîchir',
    deleteViewConfirmation:
        "Votre vue personnalisée sera supprimée de manière permanente (ceci n'affecte pas vos leads)",
    deleteSharedViewConfirmation:
        "Cette vue sera retirer de votre liste de vues partagées (ceci n'affecte pas vos leads)",
    viewTitle: 'Titre',
    disabledSavedDateTooltip:
        "Cette option n'est pas disponibles lorsque les dates sélectionnées sont relatives à la date courante",
    showElement: "Affichage de l'élement",
    to: 'à',
    of: 'sur',
    show: 'Afficher',
    elements: 'éléments',
    starGray: 'Ajouté manuellement',
    starBlue: 'Scan Xpress',
    starGreen: 'Importé du DMS',
    starOrange: 'Importé manuellement',
    starBabyBlue: '@.capitalize:lexicon.portfolio.singular automatique',
    dms: 'DMS',
    noAdvisor: 'Aucun Conseiller',
    noAgent: 'Aucun Agent',
    noAudioAccess: 'Vous n’avez pas accès à cet audio',
    noShow: 'No show',
    editSubscription: "Modifer l'abonnement",
    addSubscription: 'Ajouter un abonnement',
    displaySubscription: 'Information de l\'abonnement',
    canceled: 'Annulé',
    callAppt: 'RV tél',
    noTimeAppt: 'RV sans heure',
    removeIt: 'Oui, supprimer!',
    deleteFollowUpConfirmation:
        'Supprimer {0} est irréversible. Notez que vous pouvez modifier la date à partir de la fiche client.',
    noCampaignFound: 'Aucun événement trouvé',
    bdcAgentLabel: 'Agent BDC',
    alertDate: 'Date d\'alerte',
    alertTypes: {
        columnName: 'Types d\'alerte',
        equity: 'Équité',
        location: 'Fin de Location',
        warranty: 'Fin de Garantie',
        warranty_icon: 'G',
        equityWithPartialMatch: 'Équité. Le véhicule doit être validé pour avoir le montant exacte.',
    },
    customFields: {
        unknownVehicle: 'Véhicule Inconnu',
        vehicles: 'vehicles',
    },
    performance: {
        appointment: 'le rendez-vous',
        appointmentPhone: 'le rendez-vous téléphonique',
        appt_call: 'la date du RV téléphonique',
        appt_confirmation: 'la confirmation de rendez-vous',
        presented: 'la visite',
        presented_other: 'la visite autre',
        home_road_test: 'l\'essai routier à domicile',
        road_test: 'l\'essai routier',
        sale: 'la @:lexicon.sale.singular',
        delivered: 'la @:lexicon.delivery.singular',
        recorded: "l'enregistrement",
        sale_by_phone: 'la @:lexicon.sale.singular par téléphone',
        refinanced: 'Racheté',
        delivery: 'la date prévue de @:lexicon.delivery.singular',
        available: 'la date prévue où le véh. sera là',
        deliverable: 'la date où le véh. sera @:lexicon.deliverable.singular',
        longTerm: 'long terme',
        paperwork: 'le dossier',
    },
    deleteUpcomingTask: {
        description: 'Souhaitez-vous supprimer les tâches et les rendez-vous futurs pour ce lead ?',
        delete: 'Oui, supprimer',
        keep: 'Non, conserver',
    },
    areYouSure: 'Êtes-vous sûr?',

    process: {
        paperwork: 'le dossier',
    },
    leadState: {
        toCheck: 'À valider',
        merged: 'Fusionné',
        associated: 'Associé',
        bothStates: 'Fusionné et associé',
        noState: '-',
        none: 'Aucun',
    },
    createdMethodString: 'Méthode',
    leadStateString: 'État d\'association',
    assignedClient: 'Client assigné | Clients assignés',
    assignedLead: 'Lead assigné | Leads assignés',
    assignedWalkIn: '@.capitalize:lexicon.walkIn.singular assigné | @.capitalize:lexicon.walkIn.plural assignés',
    assignedWebOrder: 'Commande assignée | Commandes assignées',

    wanted: 'rech.',
    exchange: 'éch.',

    statsTableLoading: 'Calcul des statistiques en cours...',
    loading: 'Récupération des clients en cours, veuillez patienter SVP...',

    segments: {
        other: 'Autre',
        notSold: 'Non @:lexicon.sold.singular',
        service: 'Service',
        loyalty: 'Loyauté',
        promo: 'Promotion',
        conquest: 'Conquête',
        reminder: 'Rappel',
        endWarranty: 'Fin garantie',
        endLcap: 'Fin LCAP',
        endLnette: 'Fin LNETTE',
        nowShow: 'No show',
        csi: 'CSI',
    },

    stats: {
        headers: {
            premium: 'Premium',
            standard: 'Standard',
            activities: 'Suivi',
            averages: 'Moyenne',
            leads: 'Leads',
            performances: 'Performances',
            process: 'Processus',
            profit: 'Profit & moyenne',
            renewalLoyalty: '@.capitalize:lexicon.portfolio.singular & loyauté',
            results: 'Résultats',
            sales: '@.capitalize:lexicon.sale.plural',
            salesAndDeliveries: '@.capitalize:lexicon.sale.plural & @:lexicon.delivery.plural',
            soldServices: 'Services Vendus',
            total: 'Total',
            walkIn: '@.capitalize:lexicon.walkIn.singular',
            walkInAndPhoneUp: '@.capitalize:lexicon.walkIn.singular & Phone-up',
            phoneUp: 'Phone-up',
            visits: 'Visites',
            web: 'Web',
            withAppointments: 'Avec RV',
            withoutAppointments: 'Sans RV',
        },
    },

    ringcentral: {
        listen: 'Écouter',
        listenInRingcentral: 'Écouter dans Ringcentral',
    },

    attributionColumnFiltering: "Cette colonne va inclure les données d'attribution lors du filtrage",
};
