import Vue from 'vue';
import PortalVue from 'portal-vue';
import TextareaAutosize from 'vue-textarea-autosize';
import shadow from 'vue-shadow-dom';

import Caching from '@/plugins/caching.js';
import './plugins/vue-openreplay.js';
import App from './App.vue';
import router from './router/index.js';
import pinia from './pinia/index.js';
import mixin from './mixins/Global.js';
import './custom.js';
import './plugins.js';

// Plugins
import './plugins/outdated-browser.js';
import './plugins/modernizr.js';
import './plugins/vue-ls.js';
import i18n from './plugins/vue-i18n.js';
import wait from './plugins/vue-wait.js';
import './plugins/event-bus.js';
import './plugins/vue-auth.js';
import './plugins/vue-mfa-auth.js';
import './plugins/stats.js';
import './plugins/broadcasting.js';
import './plugins/vue-axios.js';
import './plugins/directives.js';
import './plugins/element-ui.js';
import './plugins/vue-notification.js';
import './plugins/vue-mq.js';
import './plugins/vue-scroll.js';
import './plugins/sentry.js';
import './plugins/vue-mixpanel.js';
import './plugins/activix-ui.js';
import './plugins/filters.js';
import './plugins/vue-api.js';
import './plugins/activix-player.js';
import './plugins/vue-virtual-scroller.js';
import './plugins/svg-icons.js';
import './plugins/tippy.js';
import './plugins/optimizely.js';
import './plugins/vh-fix.js';
import './plugins/vue-beamer.js';
import './plugins/vue-force-next-tick.js';
import './plugins/vue-ziggeo.js';
import './plugins/can.js';
import VueModal from './plugins/vue-modal.js';
import StatusPage from './plugins/status-page.js';
import Browser from './plugins/browser.js';
import ExtensionDetection from './plugins/chrome-extensions.js';
import { createProvider } from './plugins/vue-apollo.js';

// Styles
import './assets/less/app.less';

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.config.errorHandler = error => {
    console.error(error); // eslint-disable-line no-console
};

if (process.env.NODE_ENV != 'production') {
    window.addEventListener('message', e => {
        if (e.data && e.data.type === 'webpackInvalid') {
            console.clear(); // eslint-disable-line no-console
        }
    });
}

Vue.mixin(mixin);

Vue.use(PortalVue);
Vue.use(TextareaAutosize);
Vue.use(VueModal);
Vue.use(StatusPage);
Vue.use(Browser);
Vue.use(ExtensionDetection);
Vue.use(shadow);
Vue.use(Caching);

new Vue({
    wait,
    i18n,
    router,
    pinia,
    apolloProvider: createProvider(),
    provide: {
        $feature: Vue.prototype.$feature,
        $eventBus: Vue.prototype.$eventBus,
        $behavior: Vue.prototype.$behavior,
        $modal: Vue.prototype.$modal,
        $wait: wait,
        $auth: Vue.prototype.$auth,
        $axios: Vue.prototype.$axios,
        $notify: Vue.prototype.$notify,
    },
    render: h => h(App),
}).$mount('#app');
