export default {
    alerts: {
        index: {
            error: 'Failed fetching the accounts',
        },
        store: {
            error: 'Failed creating the account',
            success: 'Account created successfully',
        },
        show: {
            error: 'Failed fetching the account',
        },
        update: {
            error: 'Failed updating the account',
            success: 'Account updated successfully',
        },
        destroy: {
            error: 'Failed deleting the account',
            success: 'Account deleted successfully',
        },
        facebook: {
            success: 'Account connected to Facebook successfully',
        },
    },
    edit: {
        access_all_leads_communications: 'Access to communicate all leads',
        access_all_leads_communications_tool_tip: "Gives all users the ability to communicate with a lead that doesn't belong to him",
        accessories: 'Accessories',
        accidented_damage_cost: 'Accident $',
        accountClosed: 'The account is closed or suspended in Twilio. Please save the account (CRM) first.',
        accountInformation: 'Account information',
        accountName: 'Name',
        accountPhone: 'Phone number',
        accountType: 'Type',
        accSaleSpecial: 'Tires and accessories',
        activateIvr: 'Activate / deactivate IVR',
        activationDate: 'Activation date',
        activeDisabledApiUser: 'API users cannot be active',
        activeInactive: 'Active / Inactive',
        activityReport: 'Activity Report',
        actualValue: 'Actual value',
        addLogo: 'Click here to add your french logo (Default if no english one)',
        addLogoEn: 'Click here to add your english logo (Default if no french one)',
        address: 'Address',
        admin: 'Administration',
        allowedMileage: 'Allowed Mileage',
        allTime: 'At all times',
        approved: 'Approved',
        assignedLead: 'Assign lead without action',
        assignedLeadDesc: 'Allows associates and bdc agents to assign leads without having to make call or send an email (unassigned leads only).',
        audioOverwriteName: 'The use of the welcome audio file overwrites the IVR name',
        restrictLeadStatusUpdateForRoles: 'Restrict lead status for roles',
        restrictLeadStatusUpdateForRolesTooltip: 'The selected roles will not be able to assign the "lost" or "invalid" status to a lead.',
        autoAssignAssociate: 'Auto assign user during association',
        autoAssignAssociateDelay: 'Delay for auto assign',
        autoAssignAssociateTooltip: 'Automatically assign associate during lead association',
        autoAssignAssociateDelayTooltip: 'Allows you to specify the required delay for the auto-assignment of an associate to the lead.',
        enforcedMfa: 'Mandatory multi-factor authentication',
        enforcedMfaTooltip: 'Enforced multi-factor authentication for all users',
        flagLeadDuplicated: 'Flag lead as duplicated during association',
        flagLeadDuplicatedTooltip: 'Set duplicate status with an auto assignment',
        automatchIncomingCall: 'Incoming call automatching',
        automatchIncomingCallTooltip: 'Automatically link incoming calls to the lead with the corresponding phone number. Creation of a new lead if no corresponding lead.',
        automatchMandatoryTooltip: 'This option is required when RingCentral phone system is selected',
        automations: 'Automations',
        automobile: 'Dealer',
        autoRenewal: 'Auto @:lexicon.portfolio.singular',
        autoRenewalDescription: 'Automatically creates a new @:lexicon.portfolio.singular type lead after the @:lexicon.delivery.singular',
        balance: 'Balance',
        bBackDescription: 'Send the associate to the bottom of the list, even for a "B-Back" appointment',
        bBackStayRole: 'B-Back no next',
        bdcAgent: 'BDC Agent',
        bilingual: 'Bilingual',
        blacklistPhones: 'Phone number not integrated|Phone numbers not integrated',
        blacklistPhonesTooltip: 'These numbers will not be integrated into the Phone-up lead',
        bodyType: 'Body Type',
        breakStartEndTime: 'Break departure & back time',
        budget: 'Budget (min./max.)',
        budgetTextField: 'Budget',
        business: 'Business',
        businessHours: 'Business hours',
        businessHoursDivision: 'Default business hours',
        businessHoursDivisionTooltip: 'Default business hours for leads without a division.',
        calendar: 'Calendar options',
        calendarOptions: 'Calendar',
        callDate: 'Call date',
        calledCount: 'Call count',
        callerId: 'Caller ID',
        campaign: 'Campaign',
        city: 'City',
        clientNumber: 'Client #',
        closing: 'Closing',
        color: 'Color',
        certified: 'Certified',
        colorExterior: 'Exterior Color',
        colorInterior: 'Interior Color',
        comment: 'Comments',
        commercialColumn: 'F&I column',
        commercialDirector: 'F&I',
        commercialInTurn: "F&I's",
        commercialInTurnDescription: 'Add someone to the commercial managers queue',
        communicationPreference: 'Preference',
        communicationPreferenceTooltip: 'Communication preference',
        condition: 'Condition',
        confidentialProfit: 'Confidentials profits',
        confidentialProfitTooltip: 'Hide profits section for BDC and BDC director. Show only user\'s profit for an associate.',
        confirmationAppt: 'Appt. confirmation',
        confirmationApptTooltip: 'Offers the posibility to confirm an appt. by SMS for each booked appt.',
        continueWithFacebookButton: 'Continue with Facebook',
        contractEnd: 'Contract end date',
        country: 'Country',
        copyModifs: 'Copy account modifications in child accounts',
        checkAvailabilityRoundRobin: 'Check availability for Round Robin',
        checkAvailabilityRoundRobinTooltip: 'User needs to be available in schedule to be assigned a lead',
        createChildModal: 'Create child user in new child account',
        createChildModalDescription: 'Select users to create child users',
        createdMethod: 'Created method',
        credit: 'Credit',
        crmMax: 'CRM',
        csi: 'CSI',
        csiDelaysOption: 'Delays',
        csiDelaysOptionTooltip: 'Delays of CSI tasks automatic creation',
        csiTooltip: 'Automatically create "CSI" tasks following a completed @:lexicon.delivery.singular . Individual tasks will be created for each selected delay.',
        csiUsed: 'Used',
        csiNew: 'New',
        currentVehicles: 'Current vehicles',
        customInput: 'Custom fields',
        customFieldDisabledBadge: 'Used for @:lexicon.sale.singular table badge',
        day: 'Day',
        dealer: 'Dealer',
        dealerPhoneMustBeBlacklisted: "Cannot edit the dealer's phone number",
        defaultDeliveryman: 'Default @:lexicon.delivery.singular specialist',
        defaultDeliverymanTooltip: 'Select a user to be preselected as the @:lexicon.delivery.singular specialist in @:lexicon.delivery.singular tasks events',
        defaultMonthlyMileage: 'Default monthly mileage',
        defaultMonthlyMileageTooltip: 'The estimated mileage will use this value if no data is present.',
        deleteAccount: 'Delete the account',
        deliveryTimeframe: 'Default @:lexicon.delivery.singular Timeframe',
        deliveryGuestAdvisor: 'Associate as a guest',
        deliveryGuestAdvisorTooltip: 'Automatically add the associate as a guest to @:lexicon.delivery.singular tasks',
        deliveryGuestGroup: '@.capitalize:lexicon.delivery.singular guest group',
        deliveryGuestGroupTooltip: 'Automatically add the guest group to @:lexicon.delivery.singular tasks',
        departmentMoney: 'Dashboard $',
        desking: 'Desking',
        deskManagerInTurn: 'Desk managers',
        deskManagerInTurnDescription: 'Add someone to the desk managers queue',
        directionEmailPoll: 'Direction poll',
        directionPoll: 'Direction poll',
        directorAlerts: 'Directors',
        directorManagement: 'Manager only',
        directorManagementDescription: 'Prevents associates from updating their statuses (meeting, break and @:lexicon.delivery.singular)',
        disabled: 'Disabled',
        disabledManualDuplicate: 'Prevent duplicate lead creation',
        disabledManualDuplicateTooltip: 'Prevent the creation of a lead when a potential duplicate exists with the same email address',
        disconnectFacebookButton: 'Disconnect Facebook',
        displayApprovedSources: 'Display approved sources',
        displayApprovedSourcesTooltip: 'Displays the approved source when it exists instead of the original source',
        division: 'Division',
        divisionRequired: 'Division required',
        divisionGroupedTotalColumn: '@.capitalize:lexicon.sale.singular by division',
        divisionGroupedTotalColumnTooltip: 'Displays a column in your @:lexicon.sale.singular report allowing you to see the @:lexicon.sale.plural breakdown by division.',
        dmsExchange: 'DMS',
        dmsExchangeExport: 'Outbound integrations',
        dmsExchangeImport: 'Inbound integrations',
        dnsValidMessage: 'Your transactional and marketing DNS are active',
        dnsTransactionalMessage: 'Your transactional DNS is not active',
        dnsMarketingMessage: 'Your marketing DNS is not active',
        documentation: 'Documentation',
        drivingWheels: 'Drivetrain',
        duplicates: 'Duplicates',
        email: 'Email',
        emailSubdomain: 'Email Subdomain',
        employeeAlerts: 'Employees',
        endWarrantyDate: 'End warranty',
        endWarrantyMileage: 'End warranty KM',
        endExtendedWarrantyDate: 'End ext. warranty',
        endExtendedWarrantyDateTooltip: 'End extended warranty',
        endExtendedWarrantyMileage: 'End ext. warranty KM',
        endExtendedWarrantyMileageTooltip: 'End extended warranty KM',
        engine: 'Engine',
        equity_alert: 'Equity',
        event: 'Event',
        exchangeVehicleTotal: 'Total exchange vehicles',
        excluded: 'Excluded',
        extendedWarranty: 'Extended warranty',
        facebookMessenger: 'Facebook Messenger',
        fAndI: 'F&I',
        fields: 'Client card fields',
        fill: 'Complete',
        fillRuleTooltip: 'Will fill empfy fields if new data is provided',
        fiProfits: 'F&I profits',
        forthcoming: 'Forthcoming...',
        fiscalYear: 'Start of fiscal year',
        fiscalYearTooltip: 'Start date of your fiscal year used for the "@:datepicker.fiscalYearToDate" selection.',
        frequency: 'Frequency',
        from: 'From',
        fuel: 'Fuel',
        flowDefaultDivision: 'Default division flow',
        flowDefaultDivisionTooltip: 'Lead without division use this division flow for select a flow',
        general: 'General',
        generalInfo: 'General info.',
        generalOptions: 'General',
        globalCalendar: 'Global calendar',
        guestAction: 'Authorize guest modifications',
        guestActionTooltip: 'Authorize a guest in a task event to make a modification',
        gunMiss: 'Gun miss',
        gunMissDescription: 'Calculation when an associate is not available while he is next to answer.',
        homepage: 'Homepage',
        hiddenNumber: 'Hidden number',
        imapEmail: 'All IMAP user accounts for this dealer will be deactivated if you remove this option.',
        exclusions: 'Exclusions',
        imapExcludedDomains: 'Domain excluded from IMAP|Domains excluded from IMAP',
        imapExcludedDomainsTooltip: 'List of domains ignored within the IMAP bridge',
        importRule: 'Import Rule (DMS)',
        importRuleTooltip: 'During a lead merger from an import via your DMS, specify whether you wish to overwrite all the data or simply fill in data for empty fields',
        in: 'Reception',
        inactiveWillDeletePhonesText: 'Setting the account to inactive will release all its numbers',
        inactiveWillDeletePhonesTitle: 'Are you sure?',
        infinitePriorityWarning: 'In infinite mode, only the users in the first priority will have access to the leads.',
        initialCash: 'Cashdown',
        inspected: 'Inspected',
        institution: 'Institution',
        intention: 'Intention',
        inTurn: 'Who\'s Next',
        inTurnBeBack: 'Be-back meeting',
        inTurnBeBackDescription: 'Allows associates to meet a customer with a be-back (if checked in the hour before the meeting) without losing his Who\'s Next queue priority',
        inTurnOptions: 'Who\'s Next',
        ivrAudioInvalidEn: 'Audio invalid choice english',
        ivrAudioInvalidFr: 'Audio invalid choice french',
        ivrAudioMenuEn: 'Audio menu english',
        ivrAudioMenuFr: 'Audio menu french',
        ivrAudioTransferEn: 'Audio transfer english',
        ivrAudioTransferFr: 'Audio transfer french',
        ivrAudioWelcome: 'Audio greetings',
        ivrLocale: 'Language',
        ivrName: 'IVR Name',
        ivrSection: 'IVR',
        ivrServicePhone: 'Phone service',
        ivrWaitingSound: 'Waiting sound',
        leadForm: 'Form',
        leadsOtherDivision: 'Display leads from other divisions',
        leadsOtherDivisionDesc: 'Display leads from other divisions in the dashboard.',
        leadType: 'Lead Type',
        leadXpress: 'Web Lead',
        leadXpressOptions: 'Web Lead',
        leadxpressPriority: 'Web Lead priority',
        leadXpressRoundRobin: 'Flow Web Lead use Round Robin',
        leadXpressSchedule: 'Delay with schedule',
        leadXpressScheduleTooltip: 'Delay between flow and reminder will use dealer\'s schedule',
        length: 'Length (min./max.)',
        licensePlate: 'License plate',
        limitedAudioAccess: 'Limited audio access',
        limitedAudioAccessDesc: "Limit associates' access to their own audios.",
        link: 'Link',
        locale: 'Language',
        logo: 'French logo',
        logoEn: 'English logo',
        lostDirOnly: 'Lost status dir. only',
        loyalty: 'Loyalty',
        makeModelRequiredForSale: 'Make and model required for @:lexicon.sale.singular',
        makeModelRequiredForSaleTooltip: "A lead cannot be @:lexicon.sold.singular without the vehicle's make and model information",
        makeModelStockRequiredForSale: 'Make, model and stock required for @:lexicon.sale.singular',
        makeModelStockRequiredForSaleTooltip: "A lead cannot be @:lexicon.sold.singular without the vehicle's make, model and stock information",
        makeModelText: 'Make & model text input',
        mandatoryCoordinate: 'Mandatory email or phone #',
        mandatoryCoordinateTooltip: 'Upon creating a lead, the user must enter either the email or the phone number',
        manuallyStatus: 'Manually modify result',
        manuallyStatusDesc: 'Allows associates to manually modify the result of a lead even when it has outgoing communications',
        markSoldLeadAsReached: 'Mark @:lexicon.sold.singular lead as reached',
        markSoldLeadAsReachedTooltip: 'A @:lexicon.sale.singular date will automatically change the result to "reached".',
        maximumBreakDuration: 'Maximum break duration',
        mergeRule: 'Merge rule',
        mergeRuleTooltip: 'During a lead merger, specify whether you wish to overwrite all the data or simply fill in data for empty fields',
        met: 'Met',
        mileage: 'Mileage',
        mileageExcess: 'Est. mileage excess',
        mmTextFields: 'M&M Text fields',
        modality: 'Modality',
        modulesOptions: 'Modules & Options',
        music: 'Music',
        needActiveAccount: 'The account must be active to be able to buy numbers',
        needIvrNumber: 'An incoming phone number with "IVR" checked is required to use the IVR',
        new: 'New',
        never: 'Never',
        nextStep: 'Next step auto',
        nioText: 'NioText',
        nioTextAppointment: 'NioText Appointment',
        nioTextCampaign: 'NioText Campaign',
        objectives: 'Goal management',
        offerNumber: 'Offer #',
        onlyModelText: 'Only model text input',
        onlyOnUpdate: 'Not available during creation',
        open: 'Open?',
        opening: 'Opening',
        optionsMoney: 'Options',
        orderNumber: 'Order #',
        out: 'Sending',
        overwrite: 'Replace',
        overwriteRuleTooltip: 'Will replace all fields where data is provided',
        pauseRoundRobinWhenClosed: 'Pause Round Robin when closed',
        pauseRoundRobinWhenClosedTooltip: 'When the dealership is closed, the Round Robin will pause until reopening',
        payment: 'Payment',
        paymentWithTax: 'Payment with tax',
        performance: 'Performance',
        phoneProvider: 'Phone provider',
        phoneSystem: 'Phone system',
        phoneUp: 'Phone-up',
        plannedMeeting: 'Planned meeting',
        plannedMeetingDescription: 'Allows associates to meet a customer with a scheduled appointment (4 hours before of after) without losing his Who\'s Next queue priority',
        powerSport: 'Power sport',
        preferences: 'Preferences',
        prepaid: 'Prepaid',
        preparation: 'Preparation',
        prepared: 'Prepared',
        pressToTalkTooltip: 'This option requires the user to confirm the Phone-up call by pressing the "5"',
        price: 'Price',
        process: 'Process',
        profit: 'Profit',
        programmableCalendar: 'Prog. calendar',
        source: 'Source',
        providerNumber: 'Number',
        province: {
            CA: 'Province',
            US: 'State',
        },
        purchaseDate: 'Purchase date',
        qualification: 'Qualification',
        raiseStatus: 'Show revive status',
        rate: 'Rate',
        rating: 'Rating',
        reactColumn: 'React. column',
        recall: 'Recall',
        recordDate: 'Record date',
        recordDateLong: 'Record date',
        recordDateTooltip: 'Add Record date field.',
        recordingMessage: 'Recorded message',
        recordingMessageInbound: 'Recorded message inbound',
        recordingMessageOutbound: 'Recorded message outbound',
        recordingMessageRequiredTooltip: 'Recorded message required',
        recordingMessageTooltip: 'Message warning the customer that the call could be recorded',
        recreative: 'Power Sport',
        recreativeSpecial: 'Recreative special',
        refinanced: 'Buy Out',
        refinancedTooltip: 'Bought Out',
        renew: '@.capitalize:lexicon.portfolio.singular',
        renewalRequired: '@.capitalize:lexicon.portfolio.singular must be active',
        restrictedNotifications: 'SMS notifications disabled',
        restrictedNotificationsTooltip: 'Prevents users from enabling SMS notifications',
        equityRequired: 'Equity must be active',
        renewed: '@.capitalize:lexicon.renewed.singular | @.capitalize:lexicon.renewed.plural',
        repetition: 'Repetition',
        resetEveryNight: 'Daily Reset',
        residual: 'Residual',
        roundRobinTooltip: 'Auto-Assignation & Balanced Distribution must not be active',
        ringing: 'Ringing',
        sale: 'Sale',
        saleByPhone: 'Sale by phone',
        saleTableBadge: 'Badge',
        saleTableBadgeTooltip: 'Allows you to display a badge on the @.capitalize:lexicon.sale.plural Table when a custom field is defined.',
        salesChart: '@.capitalize:lexicon.sale.plural Board',
        salesChartOptions: '@.capitalize:lexicon.sale.plural board ',
        saved: 'Account saved successfully',
        scanXpress: 'Scan Xpress',
        schedule: 'Schedule',
        screen: 'Screen',
        secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
        secondaryAdvisorTooltip: 'Allows the assignment of a @:lexicon.secondaryAdvisor.singular to a lead',
        sector: 'Sector',
        securityDeposit: 'Security deposit',
        segment: 'Segment',
        selectFabebookPages: 'Which page do you want to connect to your account',
        service: 'Service',
        serviceDisabled: 'The service division will be removed from all users and service users will be disabled (including service advisors and agents)',
        servicePrices: 'Service prices',
        simultaneousEmployeeBreaks: 'Number of employees on break simultaneously',
        sleeping: 'Sleeping',
        sms: 'SMS',
        soldBy: '@.capitalize:lexicon.sold.singular by',
        soldDate: '@.capitalize:lexicon.sold.singular date',
        status: 'Status',
        stock: '# Stock',
        stockDisabledStockRequiredForSale: 'This option is required when the "Make, model and stock required on @:lexicon.sale.singular" option is active',
        stockRequiredForSale: 'Make, model and stock required on @:lexicon.sale.singular',
        stockRequiredForSaleTooltip: "Lead can't be mark as @:lexicon.sold.singular without make, model and stock information",
        stopNextStep: 'Stop next step, if',
        stopNextStepTooltip: 'Stops next-step functionality if the lead has the status of one of those selected.',
        storage: 'Storage',
        suffix: 'Suffix',
        syncChanges: 'Synchronization of changes',
        takeOverDirector: 'T.O. Dir. only',
        takeOverDirectorTooltip: 'Limits the use of the T.O. box to managers',
        team: 'Team | Teams',
        term: 'Term',
        timeLimitToAnswer: 'Delay to contact the lead',
        tire_type: 'Tire',
        tireAccessoriesColumn: 'Tires and accessories column',
        tires: 'Tires',
        tradeNotes: 'Trade notes',
        tradeReport: 'Acquisitions Report',
        tradeType: 'Buying',
        transmission: 'Transmission',
        unassignUnansweredLead: 'Remove user from unanswered lead',
        unassignUnansweredLeadTooltip: 'Remove a user from a lead that has not been answered after a specified period of time',
        updateChildAccount: 'Copy modifications made in parent account to child account',
        updateChildAccountCreateUserModal: 'Copy account modifications and/or create child user',
        unrestrictedAssignment: 'Complementary assignment',
        unrestrictedAssignmentTooltip: 'Allows the assignment of a BDC to a lead assigned to another associate',
        untreatedLeadAlert: 'Pending lead alert',
        untreatedLeadTooltip: 'An alert will only be sent if no one is in the division flow',
        used: 'Used',
        user: 'User',
        vAutoFallback: 'Default vAuto associate',
        vAutoFallbackPlaceHolder: 'vAuto Username',
        vAutoFallbackTooltip: 'Email of the vAuto user who will be able to see submission when Lead does not have an assigned associate',
        vehicleCategory: 'Vehicle category',
        vehicleCategoryRV: 'RV category',
        vehicleHereOn: 'Veh. available on',
        verifiedSale: 'Verified @:lexicon.sale.singular',
        allowCommunicationsWhenverifiedSale: 'Allows communications',
        verifiedSaleTooltip: 'Allows managers to mark @:lexicon.sale.plural as verified',
        allowCommunicationsWhenverifiedSaleTooltip: 'Allow communications when verified sale is activated',
        videoconference: 'Videoconference',
        autoRecordedDate: 'Automatic Reported on date',
        autoRecordedDateTooltip: 'Set the reported on date when the @:lexicon.delivered.singular date is selected.',
        vehicleState: 'Vehicle State',
        vin: 'VIN',
        vinDecoder: 'VIN Scanner',
        vinManualDecoder: 'VIN Decoder',
        waitingSale: 'Waiting @:lexicon.sale.singular',
        waitingSaleTooltip: 'Show @:lexicon.sale.plural that are not @:lexicon.delivered.singular, lost, refused and planned but not @:lexicon.delivered.singular outside the selected dates.',
        walkAround: 'Walk-around',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        wantedMoney: 'Wanted money',
        wantedVehicles: 'Wanted vehicles',
        wantedVehicle: 'Wanted vehicle',
        tradeInVehicle: 'Trade-in Vehicle',
        wantedVehicleTotal: 'Total wanted vehicles',
        warranty: 'Warranty',
        warrantyTypeDisableToolTip: 'There are no warranty available for this vehicle',
        webBoost: 'WebBoost',
        webOrder: 'Web Order',
        weight: 'Weight',
        worth: 'Worth',
        year: 'Year (min./max.)',

        equityAlert: '@.capitalize:lexicon.portfolio.singular',
        renewalOptions: {
            title: '@.capitalize:lexicon.portfolio.singular',
            tooltip: 'Notification sent every Monday at the opening of the dealership',
            tooltipRequiredFields: 'The following fields are required to use the alert: ',
            toolTipHasNotAccessEquity: 'Contact the CRM manager to make changes in the equity module.',
            tooltipModelsDisabled: 'Choose only one make to specify models',
            tooltipAddRenewalSetting: 'Add a condition setting',
            tooltipAddRenewalSettingDisabled: 'You need to have the equity option enabled in your account to add a condition.',
            tooltipSaveButtonDisabled: 'The condition setting requires at least a make or a year to be valid',
            tooltipExcessMileageDelay: 'Time required before the end of the warranty to generate an alert',
            tooltipExcessMileagePercentage: 'Required percentage of excess mileage to generate an alert',
            condition: 'Condition',
            equityBase: 'Default condition for the vehicle',
            equityBaseCondition: 'Condition',
            equityBaseDescription: 'This condition will be used if the current vehicle condition is undefined',
            equityBaseOptions: 'Conditions for evaluation of the vehicle',
            endLocation: 'End of lease',
            endLocationDelay: 'Notification delay',
            endWarranty: 'End of warranty',
            endWarrantyDelay: 'Notification delay',
            equity: {
                xclean: 'Extra Clean',
                extra_clean: 'Extra Clean',
                clean: 'Clean',
                average: 'Average',
                rough: 'Rough',
            },
            excessMileage: 'Excess Mileage',
            excessMileageDelay: 'Delay',
            excessMileagePercentage: 'Overflow (%)',
            makes: 'Makes',
            models: 'Models',
            missingFields:
                'The following fields are required to fully use the feature, do you wish to activate them?<br><b>{0}</b>',
            operators: {
                equals: 'Is',
                lessThan: 'Before',
                moreThan: 'After',
            },
            year: 'Year',
        },
        customFields: {
            addButton: 'Custom field',
            clientCardSection: 'Section',
            default: 'Default value',
            fieldData: 'Data',
            fieldTitle: 'Title',
            fieldType: 'Type',
            fieldTypeDisabled: 'Can not edit the type on a field used in leads.',
            modalTitle: 'Custom field',
            typeArray: 'Multiselect',
            typeBoolean: 'Checkbox',
            typeCurrency: 'Currency',
            typeDatetime: 'Date',
            typeString: 'Textbox',
            typeTextArea: 'Text area',
            typeUrl: 'URL',
            textAreaOption: 'Display',
            textAreaLine: 'One line (...)',
            textAreaBox: 'Text box',

            deleteModal: {
                confirm: 'Yes, delete it!',
                description: 'Deleting this field will delete the custom data in <span class="font-semibold">{0} leads</span>.',
                step2Description: 'This action is irreversible and will delete the data in this field for all leads.',
            },
            errors: {
                duplicateOption: 'Value already exists.',
                duplicateTitle: 'Title already exists.',
                maxOptions: 'The maximum number of {0} options has been reached.',
            },
            retroactiveModal: {
                title: 'Retroactive default value',
                confirm: 'Yes',
                description: 'Do you want this default value to be filled to existing leads?<br>This action is <span class="font-semibold">irreversible</span> and will take several minutes.',
            },
            unsavedModal: {
                confirm: 'Yes, save!',
                description: 'There is an unsaved option. Are you sure you want to save this custom field without this option?',
            },
        },
        disabledTooptips: {
            mandatoryOptions: 'Some options are mandatory',
            onlyAdmins: 'Only admins can modify this option',
            saleTableRequiresIt: 'The @.capitalize:lexicon.sale.plural Board requires this option',
            tradeReportRequires: 'The Acquisitions Report option must be enabled',
            webCrmRequired: 'The Web Lead and WebBoost CRM are required',
            webOrderRequiresIt: 'The Web Order dashboard requires this option',
        },
        guestGroup: {
            add: 'Add new user group',
            cannotAdd: 'There is no active user in this account to create new user group',
            deleteButton: 'Yes, delete group',
            deleteConfirmation: 'This user group will be permanently deleted.',
            name: 'Name',
            title: 'Guests Group',
        },
        infos: {
            childAccounts: 'Child accounts',
            parentAccount: 'Parent account',
        },
        processFields: {
            approved: 'Approved',
            available: 'Vehi. here on',
            csi: 'CSI',
            deliverable: '@.capitalize:lexicon.deliverable.singular',
            delivered: '@.capitalze:lexicon.delivered.singular',
            delivery: '@.capitalize:lexicon.deliveryDate.singular',
            discounted: 'Funded',
            inspected: 'Inspected',
            leadBank: 'Institution',
            paperwork: 'Paperwork',
            prepared: 'Prepared',
            prepared_work_order: 'P.W.O.',
            progressState: 'Approval',
            gas: 'Fueled',
            recorded: 'Reported',
            waitingSale: 'Waiting @:lexicon.sale.singular',
        },
        provinces: {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland',
            NS: 'Nova Scotia',
            NT: 'Northwest Territories',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
        recordingMessageCustomPhoneProviderTooltip: 'This option requires having Twilio as the primary phone system',
        needTwilioNumber: 'This option requires having Twilio as the primary phone system',
    },
    global: {
        goToUsers: 'Access the users list',
    },
};
