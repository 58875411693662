import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';
import VehicleDetails from '@/entities/VehicleDetails.ts';
import VehicleCondition from '@/entities/VehicleCondition.ts';
import AdditionalDetail from '@/entities/AdditionalDetail.ts';
import VehicleFinancial from '@/entities/VehicleFinancial.ts';

export default class DrsTradeInInformation {
    private _vehicle_details: VehicleDetails | null | undefined;

    private _vehicle_condition: VehicleCondition | null | undefined;

    private _additional_details: AdditionalDetail | null | undefined;

    private _vehicle_financial: VehicleFinancial | null | undefined;

    static get defaults() {
        return {
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, DrsTradeInInformation.defaults));
    }

    set vehicle_details(vehicleDetails) {
        this._vehicle_details = vehicleDetails ? new VehicleDetails(vehicleDetails) : null;
    }

    set vehicle_condition(vehicleCondition) {
        this._vehicle_condition = vehicleCondition ? new VehicleCondition(vehicleCondition) : null;
    }

    set additional_details(additionalDetails) {
        this._additional_details = additionalDetails ? new AdditionalDetail(additionalDetails) : null;
    }

    set vehicle_financial(vehicleFinancial) {
        this._vehicle_financial = vehicleFinancial ? new VehicleFinancial(vehicleFinancial) : null;
    }

    get vehicle_details(): VehicleDetails | null {
        return this._vehicle_details;
    }

    get vehicle_condition(): VehicleCondition | null {
        return this._vehicle_condition;
    }

    get additional_details(): AdditionalDetail | null {
        return this._additional_details;
    }

    get vehicle_financial(): VehicleFinancial | null {
        return this._vehicle_financial;
    }

    get isDefined(): boolean {
        return typeof this._vehicle_details !== 'undefined' ||
            typeof this._vehicle_condition !== 'undefined' ||
            typeof this._additional_details !== 'undefined' ||
            typeof this._vehicle_financial !== 'undefined';
    }
}
