<template>
    <div>
        <activix-modal
            size="xl"
            :name="name"
            :portal="portal"
            :loading="loading"
            :sticky-footer="true"
            :color="'gray'"
            :closable="closable"
            ref="modal"
            @opened="onOpened"
            @close="onClose"
            @closed="onClosed"
            @before-open="onBeforeOpen"
        >
            <template #header>
                <h4 class="modal-title">
                    {{ modalTitle }}
                </h4>
            </template>

            <template #body>
                <next-automation-alert :lead="lead" />

                <email-composer-imap-alert :lead="lead" :account="contextAccount" />

                <communicating-alert
                    :lead="lead"
                    :communicating-type="communicatingType"
                    :communicating-user="communicatingUser"
                    :recent-communication="recentCommunication"
                    :most-recent-communication="mostRecentCommunication"
                    :mass-mailing-leads-removed="massMailingLeadsRemoved"
                    :mass-mailing-leads-do-not-market="massMailingLeadsDoNotMarket"
                    @dismiss="dismiss"
                    @close="close"
                />

                <lead-xpress-multilingual
                    :class="{ loading: communicatingUser && !ownCommunication }"
                    :communicating="massMailing ? false : communicating"
                    :communicating-user="communicatingUser"
                    :current-lang="currentLang"
                    :draft="draft"
                    :is-forwarding="isForwarding"
                    :lead="lead"
                    :response.sync="response"
                    :signature="signatureByLocale"
                    :unsubscribe-link="unsubscribeLinkByLocale"
                    :mass-mailing-lead-count="massMailingLeadCount"
                    :add-last-email-body.sync="addLastEmailBody"
                    :response-templates="responseTemplates"
                    :campaign-settings="campaignSettings"
                    ref="leadXpress"
                    @change-lang="changeLanguage"
                    @set-initial-body="setInitialBody"
                    @change-locale="changeLocale"
                />
            </template>

            <template #footer>
                <div class="flex flex-col justify-between space-y-4 | xs:space-y-0 xs:flex-row">
                    <div class="flex space-x-4">
                        <activix-button
                            @click="cancelCampaignCreation"
                            v-if="isCampaign"
                        >
                            {{ $t('general.cancel') }}
                        </activix-button>
                        <span class="w-full | xs:w-auto">
                            <activix-button class="w-full" @click="reset">
                                <icon :name="$icons.loading" class="mr-2" />
                                {{ $t('mailClient.reset') }}
                            </activix-button>
                        </span>
                        <activix-tooltip :content="deleteDraftTooltip" v-if="draft">
                            <span class="w-full | xs:w-auto">
                                <activix-button
                                    class="w-full"
                                    :disabled="!!communicatingUser"
                                    @click="clearDraft(lead.id)"
                                >
                                    <span class="inline-flex items-center text-red-500">
                                        <icon :name="$icons.trash" class="mr-2" />
                                        {{ $t('mailClient.draft') }}
                                    </span>
                                </activix-button>
                            </span>
                        </activix-tooltip>
                    </div>

                    <div class="flex space-x-4">
                        <span class="w-full | xs:w-auto">
                            <activix-button
                                class="w-full"
                                :disabled="!!communicatingUser"
                                @click="triggerSave"
                                v-if="!sending && !massMailing"
                            >
                                <icon class="mr-2" name="regular/pencil-1" />
                                {{ $t('mailClient.draft') }}
                            </activix-button>
                        </span>

                        <activix-tooltip :content="sendTooltip">
                            <span class="w-full | xs:w-auto">
                                <activix-button
                                    class="w-full"
                                    :disabled="disabledSendButton"
                                    type="primary"
                                    @click="triggerCampaignReview(false)"
                                    v-if="isCampaign"
                                >
                                    {{ $t('campaign.reviewCampaign') }}
                                </activix-button>
                                <activix-button
                                    class="w-full"
                                    type="primary"
                                    :loading="sending"
                                    :disabled="disabledSendButton"
                                    @click="send(lead.id)"
                                    v-else
                                >
                                    <icon class="mr-2" name="regular/send-email" />
                                    {{ $t('mailClient.send') }}
                                </activix-button>
                            </span>
                        </activix-tooltip>
                    </div>
                </div>
            </template>
        </activix-modal>

        <activix-confirm-modal
            type="warning"
            :content="missingMultilingualDataText"
            :opened.sync="modals.missingLocale.opened"
            portal="modal-2"
            @approve="sendWithoutMultilingual"
            @closed="modals.missingLocale.opened = false"
        />
    </div>
</template>

<script>
    import TemplateType from '@/entities/TemplateType.js';

    // Utils
    import { camelCase, cloneDeep, get } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { showWarning } from '@/utils/toastr.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';

    // Components
    import LeadXpressMultilingual from '../lead_xpress/LeadXpressMultilingual.vue';
    import NextAutomationAlert from '../lead/NextAutomationAlert.vue';
    import CommunicatingAlert from '../CommunicatingAlert.vue';
    import EmailComposerImapAlert from '../EmailComposerImapAlert.vue';

    // Entities
    import CommunicationMethod from '../../entities/CommunicationMethod.js';
    import CommunicationType from '../../entities/CommunicationType.js';
    import Lead from '../../entities/Lead.js';
    import EmailValidationRisk from '../../entities/EmailValidationRisk.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            LeadXpressMultilingual,
            NextAutomationAlert,
            CommunicatingAlert,
            EmailComposerImapAlert,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            isForwarding: {
                type: Boolean,
                default: false,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
            closable: {
                type: Boolean,
                default: true,
            },
            campaignSettings: {
                type: Object,
                required: false,
            },
        },

        data() {
            return {
                draft: null,
                nextStep: 'sendEmail',
                response: {
                    category: null,
                    template: null,
                    subject: {
                        fr: '',
                        en: '',
                    },
                    to: [],
                    attachments: {
                        fr: [],
                        en: [],
                    },
                    templateAttachments: [],
                    body: {
                        fr: '',
                        en: '',
                    },
                    design: {
                        fr: null,
                        en: null,
                    },
                    civility: {
                        id: '',
                        title: '',
                    },
                    cc: [],
                    bcc: [],
                    forwardTo: [],
                    from: '',
                    fromBackup: '',
                    ziggeoId: '',
                    videoProcessed: false,
                },
                landingPageSid: null,
                attachmentErrors: [],
                communicatingUser: null,
                communicatingType: null,
                recentCommunication: null,
                mostRecentCommunication: null,
                massMailingLeadsRemoved: null,
                communicating: false,
                sent: false,
                sending: false,
                lastEmailBody: '',
                currentLang: this.$i18n.locale == 'fr' ? 'FR' : 'EN',
                submitted: false,
                lead: new Lead(),
                leadId: null,
                loading: true,
                localePreference: 'leadLocale',
                addLastEmailBody: false,
                incompleteLocales: [],
                responseTemplates: [],
                modals: {
                    missingLocale: {
                        opened: false,
                    },
                },
                emailConfig: null,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser', 'taskEventToBeCompleted', 'authUser', 'configs']),
            ...mapState(useDashboardStore, {
                massMailing: 'isMassMailing',
                dashboardCriteria: 'dashboardCriteria',
                blackListedLeadIds: 'blackListedLeadIds',
                bulkSelect: 'bulkSelect',
                pagination: 'pagination',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useClientCardStore, ['replyingEmail']),

            customCategories() {
                return this.configs.customCategories.map(category => ({
                    id: category.id,
                    locale_name: this.getCategoryLocaleName(category),
                }));
            },

            signatureByLocale() {
                return this.signature(this.currentLang.toLowerCase());
            },

            unsubscribeLinkByLocale() {
                return this.unsubscribeLink(this.currentLang.toLowerCase());
            },

            emailData() {
                return {
                    category: this.response.category,
                    subject: {
                        fr: this.response.subject.fr,
                        en: this.response.subject.en,
                    },
                    attachments: {
                        fr: this.response.attachments.fr,
                        en: this.response.attachments.en,
                    },
                    civility: {
                        id: this.response.civility.id,
                        title: this.response.civility.title,
                    },
                    to: this.response.to,
                    cc: this.response.cc,
                    bcc: this.response.bcc,
                    from: this.response.from,
                    from_backup: this.response.fromBackup,
                    body: {
                        fr: this.response.body.fr,
                        en: this.response.body.en,
                    },
                    video_processed: this.response.videoProcessed || this.videoCommunication.status === 'processed',
                    ziggeo_id: this.response.ziggeoId || this.videoCommunication.ziggeoId,
                    design: {
                        fr: this.response.design.fr,
                        en: this.response.design.en,
                    },
                    template: this.response.template,
                    isForwarding: this.isForwarding,
                    templateAttachments: this.response.templateAttachments,
                    currentLang: this.currentLang.toLowerCase(),
                    localePreference: this.localePreference,
                };
            },

            ownCommunication() {
                return this.communicatingUser?.id === this.parentAuthUser?.id || this.communicatingUser?.id === this.authUser.id;
            },

            hasRiskyEmail() {
                return this.response.to.some(email => email.validated_email?.risk == EmailValidationRisk.MEDIUM);
            },

            hasFilteredResponseTemplates() {
                return !!this.responseTemplates.length;
            },

            recording() {
                let status = '';

                if (this.response.ziggeoId) {
                    status = this.response.videoProcessed ? 'processed' : 'uploaded';
                }

                return {
                    ziggeoId: this.response.ziggeoId,
                    status,
                };
            },

            missingMultilingualDataText() {
                if (this.incompleteLocales.includes('fr')) {
                    return this.$t('email.incomplete_fr_response');
                }

                return this.$t('email.incomplete_en_response');
            },

            videoCommunication() {
                return this.$ziggeo.getVideoById(this.lead.id) || {};
            },

            hasValidMailOwner() {
                return Number.isInteger(this.response.from) || Number.isInteger(this.response.fromBackup);
            },

            needFromBackup() {
                return !Number.isInteger(this.response.from);
            },

            massMailingLeads() {
                if (!this.massMailing || this.allLeadsInTableSelected) {
                    return [];
                }

                return this.bulkSelect.filter(lead => lead.emails.length > 0 && !lead.do_not_market);
            },

            massMailingLeadsDoNotMarket() {
                if (!this.massMailing || this.allLeadsInTableSelected) {
                    return [];
                }

                return this.bulkSelect.filter(lead => lead.do_not_market);
            },

            massMailingLeadCount() {
                if (!this.massMailing) {
                    return 0;
                }

                if (!this.allLeadsInTableSelected) {
                    return this.massMailingLeads.length;
                }

                return !this.massMailingLeadsRemoved
                    ? this.pagination.total - this.blackListedLeadIds.length - this.massMailingLeadsDoNotMarket.length
                    : this.pagination.total - this.massMailingLeadsRemoved.length - this.blackListedLeadIds.length - this.massMailingLeadsDoNotMarket.length;
            },

            canSaveDraft() {
                const emptyBody = !this.response.body ||
                    this.response.body == this.initialBody('fr') ||
                    this.response.body == this.initialBody('en');

                if (this.massMailing || this.sending || this.sent || !this.lead.id) {
                    return false;
                }

                if (!this.response.subject && emptyBody) {
                    return false;
                }

                return !(
                    this.draft &&
                    this.draft.body == this.response.body &&
                    this.draft.subject == this.response.subject &&
                    this.draft.from == this.response.from &&
                    this.draft.cc == this.response.cc &&
                    this.draft.bcc == this.response.bcc &&
                    this.draft.media?.ziggeo_id == this.response.ziggeoId &&
                    this.draft.media?.video_processed == this.response.videoProcessed
                );
            },

            authorizedToSend() {
                const locales = ['fr', 'en'];

                for (const locale of locales) {
                    if (this.isAuthorized(locale)) {
                        return true;
                    }
                }

                return false;
            },

            currentUser() {
                return this.parentAuthUser.childUserForAccount(this.lead.account_id) || this.authUser;
            },

            templateAdvanced() {
                return (this.response.template?.is_advanced && this.response.template?.design) || this.response.design;
            },

            modalTitle() {
                return this.$t('mailClient.composerMessage');
            },

            disableSendReasons() {
                const reasons = [];

                if (!this.authorizedToSend) {
                    reasons.push('notAuthorizedToSend');
                }
                if (this.sending) {
                    reasons.push('sending');
                }
                if (this.communicating && !this.ownCommunication) {
                    reasons.push('communicating');
                }
                if (this.mostRecentCommunication) {
                    reasons.push('hasMostRecentCommunication');
                }
                if (!this.hasValidMailOwner) {
                    reasons.push('hasNoValidMailOwner');
                }
                if (this.isMassMailing && this.massMailingLeadCount === 0) {
                    reasons.push('hasNoRecipient');
                }
                if (
                    !empty(this.response.category) &&
                    empty(this.response.template) &&
                    this.hasFilteredResponseTemplates
                ) {
                    reasons.push('emptyTemplate');
                }
                if (['uploading', 'uploaded'].includes(this.videoCommunication.status)) {
                    reasons.push('videoUploading');
                }

                return reasons;
            },

            sendTooltip() {
                const messages = [];

                if (this.disableSendReasons.includes('notAuthorizedToSend')) {
                    if (empty(this.response.from)) {
                        messages.push(this.$t('mailClient.emptySenderTooltip'));
                    }

                    if (this.massMailing) {
                        if (empty(this.response.subject.fr) && empty(this.response.subject.en)) {
                            messages.push(this.$t('mailClient.emptySubjectTooltip'));
                        }

                        if (this.bodyIsEmpty(this.response.body.fr, 'fr') &&
                            this.bodyIsEmpty(this.response.body.en, 'en')
                        ) {
                            messages.push(this.$t('mailClient.emptyBodyTooltip'));
                        }
                    } else {
                        if (empty(this.response.subject)) {
                            messages.push(this.$t('mailClient.emptySubjectTooltip'));
                        }

                        if (empty(this.response.body)) {
                            messages.push(this.$t('mailClient.emptyBodyTooltip'));
                        }
                    }
                }

                if (this.disableSendReasons.includes('hasNoValidMailOwner')) {
                    messages.push(this.$t('mailClient.emptyFallbackSenderTooltip'));
                }

                if (this.disableSendReasons.includes('hasNoRecipient')) {
                    messages.push(this.$t('mailClient.emptyRecipientTooltip'));
                }

                if (this.disableSendReasons.includes('emptyTemplate')) {
                    messages.push(this.$t('mailClient.emptyResponseTemplateTooltip'));
                }

                if (this.disableSendReasons.includes('videoUploading')) {
                    messages.push(this.$t('mailClient.videoUploading'));
                }

                return messages.join('<br>');
            },

            deleteDraftTooltip() {
                if (!this.draft) {
                    return '';
                }

                const draftDate = new ActivixDate(this.draft.created_at).toHumanShort();

                return `
                    <span class="font-semibold">${this.$t('mailClient.draftDate')}</span>
                    <br>
                    ${draftDate}
                `;
            },

            disabledSendButton() {
                return this.disableSendReasons
                    .filter(reason => {
                        switch (reason) {
                            case 'emptyTemplate': return false;
                            default: return true;
                        }
                    })
                    .length > 0;
            },

            query() {
                if (this.allLeadsInTableSelected) {
                    return { search: this.dashboardCriteria };
                }

                if (this.bulkSelect.length && this.massMailingLeads.length) {
                    return { ids: this.massMailingLeads.map(lead => lead.id) };
                }

                return { id: this.leadId };
            },

            campaignAvailable() {
                return this.contextAccount.hasOption('campaign') && this.parentAuthUser.hasAccessTo('campaign');
            },

            isCampaign() {
                return this.campaignSettings && Object.keys(this.campaignSettings).length > 0;
            },
        },

        watch: {
            'currentUser.id': {
                immediate: true,
                async handler() {
                    this.emailConfig = await this.currentUser.email_config;
                },
            },

            '$i18n.locale'(newLocale) {
                this.currentLang = newLocale == 'fr' ? 'FR' : 'EN';
            },

            massMailingLeadsRemoved(newValue) {
                let tmpLeads = cloneDeep(this.bulkSelect);

                if (!empty(newValue)) {
                    for (let i = newValue.length - 1; i >= 0; i--) {
                        tmpLeads = tmpLeads.filter(tmpLead => tmpLead.id != newValue[i].id);
                    }
                }

                this.setBulkSelect(tmpLeads);
            },

            currentLang() {
                this.reset(true);
            },

            massMailing(newValue) {
                if (newValue) {
                    this.communicating = false;
                }
            },

            videoCommunication() {
                this.response.ziggeoId = this.videoCommunication.ziggeoId;
                this.response.videoProcessed = this.videoCommunication.status === 'processed';
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setBulkSelect',
                'setAllLeadsInTableSelected',
            ]),
            ...mapActions(useContextStore, ['setContextLeadIdAction']),
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead', 'taskEventUpdated', 'appendNewError', 'setTaskEventToBeCompleted']),

            getCategoryLocaleName(category) {
                if (!category.account_id) {
                    return this.$t(`divisions.${camelCase(category.name)}`);
                }

                if (this.contextAccount.id !== category.account_id) {
                    return `[Parent] ${category.name}`;
                }

                return category.name;
            },

            async setLastEmailBody() {
                if (this.massMailing || !this.lead.communications.length) {
                    this.lastEmailBody = {
                        fr: '',
                        en: '',
                    };
                    return;
                }

                const communications = this.lead.communications.filter(
                    c => c.communication_method_id == CommunicationMethod.EMAIL,
                );
                const latestCommunication = communications[communications.length - 1];

                if (!latestCommunication) {
                    this.lastEmailBody = {
                        fr: '',
                        en: '',
                    };
                    return;
                }

                const response = await this.$axios.get(`v1/communications/${latestCommunication.id}`, {
                    include: 'user',
                });

                const communication = response.data.data;

                if (communication.lead_id != this.lead.id) {
                    this.lastEmailBody = {
                        fr: '',
                        en: '',
                    };
                    return;
                }

                let senderName = this.lead.fullName;
                let senderEmail = communication.client_email ? `&lt;${communication.client_email}&gt;` : '';

                if (communication.communication_type_id == CommunicationType.OUTGOING) {
                    senderName = this.getFullName(communication.user);
                    senderEmail = communication.user_email ? `&lt;${communication.user_email}&gt;` : '';
                }

                if (senderName) {
                    senderName += ' ';
                }

                const date = new ActivixDate(communication.created_at).toHumanShort();

                this.lastEmailBody = `
                    <div class="last-email-body">
                        ${this.$t('email.on')} ${date}, ${senderName}${senderEmail}, ${this.$t('email.wrote')}:<br>
                        <blockquote type="cite">
                            ${communication.email_body}
                        </blockquote>
                    </div>
                `;
            },

            strippedHtml(content) {
                const regex = /(<([^>]+)>)/ig;

                return content.replace(regex, '');
            },

            bodyIsEmpty(body, locale) {
                return body === '' || this.strippedHtml(body) === '' || body == this.initialBody(locale);
            },

            async deleteDraft(leadId) {
                this.draft = null;

                if (leadId) {
                    this.$axios.delete(`v1/leadxpress/draft/${leadId}`);
                }

                if (!this.sent && ['uploading', 'uploaded', 'processed'].includes(this.videoCommunication.status)) {
                    await this.$ziggeo.deleteVideo(this.lead.id, this.videoCommunication.ziggeoId);
                }
            },

            clearDraft(leadId) {
                this.deleteDraft(leadId);

                this.reset();
            },

            isAuthorized(locale) {
                if (this.massMailing) {
                    return (
                        !empty(this.response.subject[locale]) &&
                        !this.bodyIsEmpty(this.response.body[locale], locale) &&
                        (
                            !this.fallbackOwnerShouldBeVisible ||
                            !this.sent &&
                            empty(this.attachmentErrors) &&
                            !!this.massMailingLeadCount
                        )
                    );
                }

                if (!empty(this.response.template) && this.hasFilteredResponseTemplates) {
                    if (
                        !empty(this.response.subject[locale]) &&
                        !empty(this.response.to) &&
                        !empty(this.response.body[locale]) &&
                        empty(this.attachmentErrors) &&
                        !this.sent
                    ) {
                        return true;
                    }
                }

                if (
                    !empty(this.response.subject[locale]) &&
                    !empty(this.response.to) &&
                    !empty(this.response.body[locale]) &&
                    empty(this.attachmentErrors) &&
                    this.response.cc.every(cc => this.emailIsValid(cc)) &&
                    this.response.bcc.every(bcc => this.emailIsValid(bcc)) &&
                    !this.sent
                ) {
                    return true;
                }

                return false;
            },

            setSubject() {
                const prefix = this.isForwarding ? 'Fw:' : 'Re:';

                const communications = this.lead.communications;

                const communication = communications.reverse().find(communication => {
                    return (
                        !empty(communication.email_subject) &&
                        communication.communication_method_id == CommunicationMethod.EMAIL &&
                        communication.communication_type_id == CommunicationType.INCOMING
                    );
                });

                if (!communication) return prefix;

                return `${prefix} ${communication.email_subject}`;
            },

            setInitialBody() {
                this.response.body = {
                    fr: this.initialBody('fr'),
                    en: this.initialBody('en'),
                };
            },

            signature(locale = null) {
                if (!locale) {
                    locale = this.currentLang.toLowerCase();
                }

                if (!this.templateAdvanced && this.response.from != this.currentUser.id) {
                    return `
                        <div class="signature">
                            <h4 class="name">
                                <div contentEditable="false" class="max-w-sm w-full">
                                    <div class="flex space-x-4">
                                        <div class="flex-1 space-y-4 py-1">
                                            <div class="h-9 bg-gray-200 rounded w-3/4 mb-3"></div>
                                            <div class="space-y-2">
                                                <div class="h-4 bg-gray-200 rounded mb-2"></div>
                                                <div class="h-4 bg-gray-200 rounded w-5/6"></div>
                                            </div>
                                            ${this.accountLogo}
                                        </div>
                                    </div>
                                </div>
                            </h4>
                        </div>
                    `;
                }
                const key = `signature_${locale}`;
                const signature = get(this.emailConfig || {}, key);

                if (!signature) {
                    return '';
                }

                return `
                    <div class="signature">
                        ${signature}
                        ${this.accountLogo(locale)}
                    </div>
                `;
            },

            accountLogo(locale = null) {
                if (!locale) {
                    locale = this.currentLang.toLowerCase();
                }

                const logoSource = locale == 'fr' ? 'logo' : 'logo_en';

                const logo =
                    get(this.currentLead, `account.${logoSource}`) || get(this.currentUser, `account.${logoSource}`);

                if (!logo) {
                    return '';
                }

                return `<img src="${logo}" style="max-width: 400px; max-height:125px">`;
            },

            initialBody(locale = null) {
                if (!locale) {
                    locale = this.currentLang.toLowerCase();
                }

                let body = `
                    <div class="body"></div>
                    <p><br><br></p>
                    ${this.signature(locale)}
                `;

                if (this.unsubscribeLink(locale)) {
                    body += `
                        <div><br><hr></div>
                        ${this.unsubscribeLink(locale)}
                   `;
                }

                body += '<div class="original-communication"></div>';

                return body;
            },

            unsubscribeLink(locale = null) {
                if (!locale) {
                    locale = this.currentLang.toLowerCase();
                }

                if (
                    !this.emailConfig ||
                    (this.emailConfig.active && !this.massMailing)
                ) {
                    return '';
                }

                const accountPhone = this.formatPhone(this.currentUser.account.phone, true);

                let unsubscribeText = '<a href="%tag_unsubscribe_url%" style="text-decoration: none;">';

                if (locale === 'en') {
                    unsubscribeText += `
                        Click here</a> to stop receiving communications from ${this.currentUser.account.name} or contact us at ${accountPhone} to be removed from our list.
                    `;
                } else {
                    unsubscribeText += `
                        Cliquez ici</a> pour ne plus recevoir de communications de la part de ${this.currentUser.account.name} ou communiquez avec nous au ${accountPhone} afin d'être retiré de notre liste.
                    `;
                }

                return `
                    <div class="unsubscribe-link" style="color: #B0B0B0; font-size: 12px;">
                        ${unsubscribeText}
                    </div>
                `;
            },

            reset($event, soft = false) {
                this.response.category = null;
                this.response.template = null;
                this.localePreference = 'leadLocale';
                this.nextStep = 'sendEmail';

                if (!soft) {
                    this.response.subject =
                        {
                            fr: '',
                            en: '',
                        };
                    this.response.attachments =
                        {
                            fr: [],
                            en: [],
                        };
                    this.response.templateAttachments = [];
                    this.response.body = {
                        fr: this.initialBody('fr'),
                        en: this.initialBody('en'),
                    };
                    this.response.videoHash = '';
                    this.response.to = [];
                    this.response.cc = [];
                    this.response.bcc = [];
                    this.response.from = this.currentUser.id;
                    this.response.fromBackup = '';
                    this.response.ziggeoId = '';
                    this.response.videoProcessed = false;
                    this.response.design = {
                        fr: '',
                        en: '',
                    };
                }

                this.sent = false;
                this.draft = null;
                this.addLastEmailBody = false;
                this.$refs.leadXpress?.updateDefault();
            },

            displaySuccessNotification() {
                if (this.hasRecipient) {
                    this.$notify.success(this.$t('email.success_mass_mailing'));
                } else {
                    this.$notify.success(this.$t('email.success'));
                }
            },

            sendWithoutMultilingual() {
                if (this.incompleteLocales.includes('en')) {
                    this.localePreference = 'fr';
                } else {
                    this.localePreference = 'en';
                }

                if (this.nextStep == 'campaignReview') {
                    this.triggerCampaignReview();
                    return;
                }

                this.send(this.lead.id, 0, true);
            },

            fillMissingMultilingualData() {
                const filledLocale = this.currentLang.toLowerCase();
                const toFillLocale = filledLocale === 'fr' ? 'en' : 'fr';

                this.response.subject[toFillLocale] = this.response.subject[filledLocale];
                this.response.body[toFillLocale] = this.response.body[filledLocale];
                this.response.attachments[toFillLocale] = this.response.attachments[filledLocale];
                this.response.design[toFillLocale] = this.response.design[filledLocale];
            },

            async createLandingPage() {
                const landingPageResponse = await this.$ziggeo.createLandingPage(
                    this.lead.id,
                    this.currentUser.id,
                    {
                        title: this.response.subject,
                        leadSid: this.lead.sid,
                        accountSid: this.lead.account.sid,
                    },
                );

                this.landingPageSid = landingPageResponse.data.data.sid;
            },

            async send(leadId, retryCount = 0, useOneLocale = false) {
                if ((this.sending && retryCount === 0) || (!this.massMailing && !leadId)) {
                    return;
                }

                if (this.massMailing &&
                    !this.multilingualDataComplete() &&
                    !useOneLocale &&
                    (this.localePreference === 'leadLocale' || this.incompleteLocales.includes(this.localePreference))
                ) {
                    this.modals.missingLocale.opened = true;
                    return;
                }

                if (!this.massMailing) {
                    this.fillMissingMultilingualData();
                }

                this.sending = true;
                this.submitted = true;

                if (['uploaded', 'processed'].includes(this.videoCommunication.status)) {
                    try {
                        await this.createLandingPage();
                        await this.$ziggeo.updateZiggeoVideo(
                            this.videoCommunication.ziggeoId,
                            { tags: 'sent' },
                        );
                        this.$ziggeo.resetVideo(this.lead.id);
                    } catch (error) {
                        if (error.response && error.response.status === 403) {
                            this.close();
                        }

                        this.$notify.error(this.$t('communications.video.statusMessage.videoCommunicationFail'));

                        this.response.videoProcessed = false;
                        this.response.ziggeoId = '';
                        this.sending = false;
                        this.submitted = false;
                        this.sent = false;

                        return;
                    }
                }

                const payload = new FormData();
                const data = {};

                // Send email
                data.subject = this.response.subject;
                data.isForwarding = this.isForwarding;

                if (this.isForwarding) {
                    data.to = [];
                    data.forwardTo = this.response.forwardTo;
                } else {
                    data.to = !this.massMailing ? this.response.to.map(email => email.id) : [];
                    data.forwardTo = [];
                }

                data.body = this.response.body;

                if (this.addLastEmailBody && this.lastEmailBody) {
                    data.body += `
                        <div><br></div>
                        ${this.lastEmailBody}
                    `;
                }

                data.currentLang = this.currentLang.toLowerCase();
                data.cc = this.response.cc;
                data.bcc = this.response.bcc;
                data.videoCommunication = {
                    ziggeoId: this.response.ziggeoId,
                    landingPageSid: this.landingPageSid,
                };

                // Save draft if email send failed
                data.category = this.response.category;
                data.template = this.response.template;
                data.response_template_id = !empty(this.response.template) ? this.response.template.id : null;
                data.locale = this.currentLang.toLowerCase();
                data.localePreference = this.localePreference;

                data.from = this.response.from;

                if (this.needFromBackup) {
                    data.from_backup = this.response.fromBackup;
                }

                payload.append('data', JSON.stringify(data));
                payload.append('accountId', this.contextAccount.id);

                // Add Attachments
                Object.entries(this.response.attachments).forEach(([key, attachmentsByLocale]) => {
                    attachmentsByLocale.forEach(attachment => {
                        if (attachment.id) {
                            payload.append(`existingAttachments[${key}][]`, JSON.stringify(attachment));
                        } else {
                            payload.append(`attachments[${key}][]`, attachment);
                        }
                    });
                });

                if (this.allLeadsInTableSelected) {
                    this.blackListedLeadIds.forEach(blackListedLeadId => {
                        payload.append('blackListedLeadIds[]', blackListedLeadId);
                    });
                }

                // Send Email
                try {
                    await this.$api.leads.email(this.query, payload);

                    this.sent = true;

                    if (!this.massMailing) {
                        // Delete Draft
                        this.deleteDraft(leadId);
                    }

                    // Show success
                    this.displaySuccessNotification();

                    // Complete taskEvent if necessary
                    if (this.taskEventToBeCompleted) {
                        await this.$axios.post(`v1/reminder/done/${this.taskEventToBeCompleted}`).then(({ data }) => {
                            if (!data.success) {
                                return;
                            }

                            this.taskEventUpdated(data.taskEvent);
                        });
                    }

                    this.setContextLeadIdAction(leadId, true);

                    this.$nextTick(() => {
                        // Close modal
                        this.close();

                        this.sending = false;
                    });
                } catch (error) {
                    const leadCount = this.massMailing ? this.massMailingLeadCount : 1;

                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                showWarning(
                                    this.$tc('emailLead.invalidEmail', leadCount, { lead: this.lead.fullName }),
                                );
                                this.sent = false;
                                this.sending = false;
                                return;

                            case 403:
                                showWarning(this.$tc('emailLead.cantSendEmail', leadCount));
                                this.sent = false;
                                this.sending = false;
                                return;

                            case 406:
                                showWarning(this.$t('emailLead.unsubscribeRequired'));
                                this.sent = false;
                                this.sending = false;
                                return;

                            case 422:
                                showWarning(this.$t('emailLead.cantSendSameEmail'));
                                this.sent = false;
                                this.sending = false;
                                return;
                        }

                        this.appendNewError({
                            code: '0099',
                            display: true,
                            error,
                            request: {
                                payload,
                            },
                        });

                        this.sent = false;
                    } else if (String(error).includes('Error: Network Error')) {
                        showWarning(this.$t('toastr.errorNetwork'), 0);

                        this.sent = false;
                    } else {
                        // Show success for the sent because the error occured after the request
                        this.displaySuccessNotification();

                        this.close();
                    }

                    this.sending = false;
                }
            },

            multilingualDataComplete() {
                this.incompleteLocales = [];

                if (this.response.body.fr == '' || this.response.subject.fr == '') {
                    this.incompleteLocales.push('fr');
                }

                if (this.response.body.en == '' || this.response.subject.en == '') {
                    this.incompleteLocales.push('en');
                }

                return !this.incompleteLocales.length;
            },

            triggerSave() {
                this.submitted = true;
                this.close();
            },

            triggerCampaignReview(useOneLocale = false) {
                if (this.massMailing &&
                    !this.multilingualDataComplete() &&
                    !useOneLocale &&
                    (this.localePreference === 'leadLocale' || this.incompleteLocales.includes(this.localePreference))
                ) {
                    this.nextStep = 'campaignReview';
                    this.modals.missingLocale.opened = true;
                    return;
                }

                this.$emit('reviewCampaign', this.emailData);

                this.close();
            },

            changeLanguage(event) {
                this.currentLang = event;
            },

            changeLocale(event) {
                this.localePreference = event;
            },

            isDraftTranslated(draft) {
                return (
                    draft.subject_translation.en != '' || draft.subject_translation.fr != '' ||
                    draft.body_translation.en != '' || draft.body_translation.fr != '' ||
                    draft.design_translation.en != '' || draft.design_translation.fr != ''
                );
            },

            async saveDraft() {
                if (!this.canSaveDraft) {
                    return;
                }

                const payload = {
                    response_template_custom_category_id: this.response.category?.id,
                    subject: {
                        fr: this.response.subject.fr,
                        en: this.response.subject.en,
                    },
                    to: this.response.to,
                    cc: this.response.cc,
                    bcc: this.response.bcc,
                    from: this.response.from,
                    body: {
                        fr: this.response.body.fr,
                        en: this.response.body.en,
                    },
                    video_processed: this.response.videoProcessed || this.videoCommunication.status === 'processed',
                    ziggeo_id: this.response.ziggeoId || this.videoCommunication.ziggeoId,
                    design: {
                        fr: this.response.design.fr,
                        en: this.response.design.en,
                    },
                    template: this.response.template,
                    templateAttachments: this.response.templateAttachments,
                    locale: this.currentLang.toLowerCase(),
                };

                try {
                    const draft = await this.$axios.put(`v1/leadxpress/draft/${this.lead.id}`, payload);

                    if (['uploaded', 'processed'].includes(this.videoCommunication.status)) {
                        await this.$ziggeo.updateZiggeoVideo(
                            this.videoCommunication.ziggeoId,
                            {
                                data: {
                                    account_sid: this.authUser.account.sid,
                                    user_id: this.authUser.id,
                                    lead_id: this.lead.id,
                                    draft_id: draft.data.data.id,
                                },
                            },
                        );
                    }
                } catch (error) {
                    this.appendNewError({
                        code: '0100',
                        display: false,
                        error,
                        payload,
                    });
                }
            },

            async fetchDraft(leadId) {
                try {
                    const response = await this.$axios.get(`v1/leadxpress/draft/${leadId}`);

                    const draft = response.data.data;

                    if (!draft || draft.lead_id != leadId) {
                        return;
                    }

                    if (draft.body) {
                        if (draft.body != this.response.body || !empty(draft.response_template_id)) {
                            this.draft = draft;
                        }
                    } else if ((draft.body_translation.fr != this.response.body.fr && draft.body_translation.en != this.response.body.en) || !empty(draft.response_template_id)) {
                        this.draft = draft;
                    }

                    this.currentLang = draft.locale.toUpperCase();

                    // Template
                    if (!empty(draft.response_template)) {
                        this.response.templateAttachments = draft.response_template.response_template_attachments;

                        this.response.template = draft.response_template;
                    }

                    if (draft?.media?.ziggeo_id) {
                        const tempMedia = draft.media;
                        const response = await this.$ziggeo.getVideoStateFromZiggeo(draft.media.ziggeo_id);
                        draft.media = null;

                        response.success(() => {
                            this.response.videoProcessed = draft.media?.video_processed;
                            this.response.ziggeoId = draft.media?.ziggeo_id;
                            draft.media = tempMedia;
                            this.draft.media = tempMedia;
                        });
                    }

                    const isTranslatedDraft = this.isDraftTranslated(draft);

                    // Required to make sure it overwrites template automation
                    this.$nextTick(() => {
                        // for transition period
                        if (draft.division) {
                            this.response.category = draft.division;
                        }

                        if (draft.category) {
                            this.response.category = draft.category;
                        }

                        this.response.to = draft.lead_emails;
                        this.response.cc = draft.cc;
                        this.response.bcc = draft.bcc;
                        this.response.from = parseInt(draft.from, 10) || this.currentUser.id;

                        if (isTranslatedDraft) {
                            if (!this.replyingEmail) {
                                this.response.subject = draft.subject_translation;
                            }

                            this.response.design = draft.design_translation;
                            this.response.body = draft.body_translation;
                        } else {
                            if (!this.replyingEmail) {
                                this.response.subject.fr = draft.subject;
                            }

                            this.response.design.fr = draft.design;
                            this.response.body.fr = draft.body;
                        }

                        this.draft = draft;
                    });
                } catch (error) {
                    if (!error.response || error.response.status == 422) {
                        return;
                    }

                    this.appendNewError({
                        code: '0101',
                        display: false,
                        error,
                    });
                }
            },

            async removeCommunicating() {
                if (this.massMailing) {
                    return;
                }

                await this.$api.leads.removeCommunicating(this.query, {
                    blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                });
            },

            async setCommunicating() {
                if (!this.$refs.modal?.exists) {
                    return;
                }

                this.communicating = false;

                const response = await this.$api.leads.setCommunicating(this.query, {
                    type: CommunicationMethod.EMAIL,
                    blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                });

                const { data } = response.data;

                if (!this.massMailing) {
                    if (data.user !== false) {
                        this.massMailingLeadsRemoved = null;
                        this.communicating = true;
                        this.communicatingUser = data.user;
                        this.communicatingType = data.type;
                        this.recentCommunication = null;
                    } else if (data.communication !== false) {
                        this.massMailingLeadsRemoved = null;
                        this.communicating = false;
                        this.communicatingUser = null;
                        this.communicatingType = null;
                        this.recentCommunication = data.communication;
                    } else {
                        this.massMailingLeadsRemoved = null;
                        this.communicating = false;
                        this.communicatingUser = null;
                        this.communicatingType = null;
                        this.recentCommunication = null;
                    }
                } else if (Array.isArray(data.leads?.oldCommunicating) && data.leads?.oldCommunicating.length) {
                    this.massMailingLeadsRemoved = data.leads.oldCommunicating;
                    this.communicating = false;
                    this.communicatingUser = null;
                    this.communicatingType = null;
                    this.recentCommunication = null;
                } else {
                    this.massMailingLeadsRemoved = null;
                    this.communicating = false;
                    this.communicatingUser = null;
                    this.communicatingType = null;
                    this.recentCommunication = null;
                }
            },

            // Check if there has been communication with the email before, if so display options
            async checkOldCommunication() {
                if (!this.$refs.modal?.exists) {
                    return;
                }

                const response = await this.$axios.get(`v1/leadxpress/check-old-communication/${this.lead.id}`);

                if (!empty(response.data.mostRecentCommunication)) {
                    this.mostRecentCommunication = response.data.mostRecentCommunication;
                }
            },

            async loadResponseTemplates() {
                if (!this.contextAccount.responseTemplatesLoaded) {
                    await this.contextAccount.loadResponseTemplates();
                }

                this.responseTemplates = this.contextAccount.responseTemplates.filter(template => {
                    return (
                        template.type === TemplateType.EMAIL &&
                        template.translations.length
                    );
                });
            },

            cancelCampaignCreation() {
                this.$eventBus.$emit('cancel-campaign-creation');
            },

            dismiss() {
                this.mostRecentCommunication = null;
            },

            onClose() {
                this.removeCommunicating();
                this.saveDraft();
            },

            close() {
                this.$refs.modal.hide();
            },

            async onOpen(leadId) {
                if (leadId && this.massMailing) {
                    this.setAllLeadsInTableSelected(false);
                }

                await this.setLastEmailBody();

                this.reset();

                if (this.replyingEmail) {
                    this.response.subject = this.setSubject();
                    this.addLastEmailBody = true;
                }

                const promises = [];

                promises.push(this.loadResponseTemplates());

                if (!this.massMailing) {
                    this.communicating = true;

                    promises.push(this.setCommunicating());
                    promises.push(this.checkOldCommunication());
                    promises.push(this.fetchDraft(leadId));
                } else {
                    promises.push(this.setResponseFromCampaignSettings());
                }
                await Promise.all(promises);
            },

            onOpened() {
                this.currentLang = (this.lead.locale || this.$i18n.locale).toUpperCase();

                // iFrame
                const iframe = $(this.$refs.leadXpress.$refs.iframe);

                if (iframe.length != 0) {
                    if (typeof iframe[0] !== 'undefined' && typeof iframe[0].iFrameResizer !== 'undefined') {
                        iframe[0].iFrameResizer.resize();
                    } else {
                        iframe.iFrameResize({ autoResize: false });
                    }
                }
            },

            onClosed() {
                if (this.submitted && this.sent) {
                    this.massMailingLeadsRemoved = null;
                    this.communicatingUser = null;
                    this.communicatingType = null;
                    this.recentCommunication = null;
                    this.mostRecentCommunication = null;
                    this.communicating = false;
                    this.lastEmailBody = '';

                    if (this.taskEventToBeCompleted) {
                        this.setTaskEventToBeCompleted(null);
                    }

                    // Open Next Step if necessary
                    if (!this.massMailing) {
                        this.$eventBus.$emit('show-next-step', { lead: this.lead });
                    }

                    this.setAllLeadsInTableSelected(false);
                }

                this.submitted = false;
                this.leadId = null;
                this.lead = new Lead();
                this.loading = true;
                useClientCardStore().replyingEmail = false;
            },

            async onBeforeOpen(leadId) {
                if (leadId) {
                    this.leadId = leadId;
                    this.lead = await this.fetchLead(leadId);
                }

                await this.onOpen(leadId);
                this.loading = false;
            },
            backToCampaignCreatingSetting() {
                this.$emit('openCampaignSettings', this.emailData);
                this.close();
            },
            async setResponseFromCampaignSettings() {
                const response = this.response;
                if (this.campaignSettings?.campaign?.emailData) {
                    response.category = this.campaignSettings.campaign.emailData?.category;
                    response.template = this.campaignSettings.campaign.emailData?.template;
                    response.to = this.campaignSettings.campaign.emailData?.to;
                    response.cc = this.campaignSettings.campaign.emailData?.cc;
                    response.bcc = this.campaignSettings.campaign.emailData?.bcc;
                    response.forwardTo = this.campaignSettings.campaign.emailData?.forwardTo;
                    response.from = this.campaignSettings.campaign.emailData?.from;
                    response.fromBackup = this.campaignSettings.campaign.emailData?.from_backup;
                    response.civility = this.campaignSettings.campaign.emailData?.civility;
                    this.localePreference = this.campaignSettings.campaign.emailData?.localePreference;

                    this.$nextTick(() => {
                        response.attachments = this.campaignSettings.campaign.emailData?.attachments;
                        response.templateAttachments = this.campaignSettings.campaign.emailData?.templateAttachments;
                        response.subject.fr = this.campaignSettings.campaign.emailData.subject.fr;
                        response.subject.en = this.campaignSettings.campaign.emailData.subject.en;
                        response.body.fr = this.campaignSettings.campaign.emailData?.body.fr;
                        response.body.en = this.campaignSettings.campaign.emailData?.body.en;
                        response.design.fr = this.campaignSettings.campaign.emailData?.design.fr;
                        response.design.en = this.campaignSettings.campaign.emailData?.design.en;
                        this.response = response;
                    });
                }
            },
        },
    };
</script>
