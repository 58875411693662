import { combineInitialValues, initValues } from '@/entities/ModelHelper.ts';

export default class ValueImpactOption {
    private description: string = '';

    private selected: boolean = false;

    private amount: number = 0;

    static get defaults() {
        return {
            description: '',
            selected: false,
            amount: 0,
        };
    }

    constructor(initialValues: any = {}) {
        initValues(this, combineInitialValues(initialValues, ValueImpactOption.defaults));
    }
}
