<template>
    <div class="flex">
        <activix-button
            class="flex-1 px-4"
            size="small"
            :type="selectedForViewing ? 'primary' : 'default'"
            @click="$emit('complete')"
            v-if="showCompleteButton"
        >
            {{ hasCompletableValues ? $t('addLead.complete') : $t('addLead.select') }}
        </activix-button>
        <activix-tooltip :content="associationTooltip">
            <div>
                <activix-button
                    class="flex-1 ml-4 px-4 | lg:ml-2"
                    size="small"
                    :loading="associationInProgress"
                    :disabled="associationDisabled"
                    :type="selectedForViewing ? 'primary' : 'default'"
                    @click="$emit('associate')"
                >
                    {{ $t('addLead.associate') }}
                </activix-button>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    export default {
        inject: ['$association'],

        props: {
            selectedForViewing: {
                required: true,
            },
            hasCompletableValues: {
                required: true,
            },
        },

        computed: {
            showCompleteButton() {
                return !this.$association().leadId;
            },

            associationDisabled() {
                if (!this.selectedForViewing) {
                    return false;
                }

                return this.$association().disabled;
            },

            associationTooltip() {
                if (!this.selectedForViewing) {
                    return '';
                }

                return this.$association().tooltip;
            },

            associationInProgress() {
                if (!this.selectedForViewing) {
                    return false;
                }

                return this.$association().creating;
            },
        },
    };
</script>
