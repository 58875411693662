var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button-icon',{attrs:{"button-class-name":[
        'border-gray-200 divide-x divide-gray-200 py-3',
        'w-14 md:w-18',
        'transition-all duration-300',
        _vm.position === 'right' && 'border-l last:rounded-tr-lg',
        _vm.position === 'left' && 'border-r last:rounded-tl-lg',
        _vm.buttonClassName
    ],"disabled":_vm.disabled,"icon-name":_vm.iconName,"icon-class-name":_vm.iconClassName,"icon-size-class-name":_vm.iconSizeClassName,"indicator":_vm.indicator,"indicator-class-name":_vm.indicatorClassName,"indicator-type":_vm.indicatorType,"tooltip":_vm.tooltip},on:{"click":function($event){return _vm.$emit('click')}},scopedSlots:_vm._u([(_vm.$scopedSlots.popoverContent)?{key:"popoverContent",fn:function({ closePopover }){return [_vm._t("popoverContent",null,{"closePopover":closePopover})]}}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }