import { gql } from 'apollo-boost';
import { apolloClient } from '@/plugins/vue-apollo.js';

export default {
    async index(leadIds, queryName) {
        const communicationsQuery = apolloClient.query({
            query: gql`query ${queryName || 'communications'}($leadIds: [Int]) {
                communications(lead_ids: $leadIds) {
                    id
                    lead_id
                    user_id
                    automation_action_id
                    campaign_id

                    affect_lead_status
                    appointment
                    bounced_at
                    bounced_reason
                    clicked
                    client_email
                    communication_method_id
                    communication_type_id
                    connected
                    created_at
                    created_by_api
                    created_by_user
                    description
                    duration_call
                    duration_reached
                    email_bcc
                    email_cc
                    email_read_at
                    email_subject
                    excerpt
                    exchange_counter
                    external_content
                    from_phone
                    in_hours
                    in_schedule
                    inbound_counter
                    kind
                    media_id
                    message_id
                    original_created_at
                    outbound_counter
                    phone
                    phone
                    provider_queue_id
                    reaction
                    receptionist
                    ringcentral_recording_status
                    ringcentral_url
                    service_id
                    status
                    status
                    to_phone
                    total_clicks
                    url
                    user_seen_email_read_at
                    video_room

                    call_log {
                        id
                    }

                    campaign {
                        id
                        name
                    }

                    lead {
                        id
                        user_id
                        bdc_user_id

                        business
                        business_name
                        first_name
                        last_name
                        unsubscribe_email
                        created_at

                        lead_emails{
                            id
                            lead_id
                            email
                            valid
                        }
                    }

                    user {
                        id
                        parent_user_id
                        first_name
                        last_name
                    }

                    user_phone {
                        id
                    }
                    automation_action {
                        id
                        automation_id
                    }
                }
            }`,
            variables: {
                leadIds,
            },
        });

        return (await communicationsQuery).data.communications;
    },
};
